import { Badge } from "@chakra-ui/react";
import React from "react";
import {
  getRideStatColor,
  getUserStatColor,
} from "../../../config/constants/appColors";
import { RIDE_STATUS, USER_STATUS } from "../../../config/constants/enums";

const StatusBadge = ({
  value,
  activeStatus = 1,
  isLoading,
  loadingText = "Loading...",
  bookingStatus,
  userStatus,
  ...rest
}) => {
  if (bookingStatus) {
    return (
      <Badge
        textAlign={"center"}
        minW="61px"
        bg={getRideStatColor(value)}
        color="#fff"
        {...rest}
      >
        {isLoading ? loadingText : RIDE_STATUS[value] || "N/A"}
      </Badge>
    );
  }
  if (userStatus) {
    return (
      <Badge
        textAlign={"center"}
        minW="61px"
        colorScheme={getUserStatColor(value)}
        {...rest}
      >
        {isLoading ? loadingText : USER_STATUS[value] || "N/A"}
      </Badge>
    );
  }
  return (
    <Badge
      textAlign={"center"}
      minW="61px"
      colorScheme={value?.id === activeStatus ? "green" : "red"}
      {...rest}
    >
      {isLoading ? loadingText : value?.name || value || "N/A"}
    </Badge>
  )
}

export default React.memo(StatusBadge);
