import React, { useEffect, useState, useRef } from 'react'
import { useNotifications } from '../../../config/query/notificationQuery';
import { Divider, VStack } from '@chakra-ui/react';
import CircularLoading from '../../LoadingElements/CircularLoading';
import NoResults from '../../SVGComponents/NoResults';
import sortOrders from '../../../config/constants/sortOrders';
import NotificationItem from './NotificationItem';

const Notifications = () => {
    const notificationListRef = useRef();

    const [notifications, setNotifications] = useState([]);

    const {
        data: notificationsData,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useNotifications({
        PageNumber: 1,
        PageSize: 10,
        SortOrder: sortOrders.DESC,
    })

    const handleFetchNextPage = () => {
        if (hasNextPage) {
            fetchNextPage({ pageParam: Number((notificationsData?.pages?.at(notificationsData?.pages?.length - 1)?.meta?.currentPage + 1)) || 1 });
        }
    };

    const handleScroll = () => {
        if (notificationListRef.current) {
            const bottom = notificationListRef.current.scrollHeight - notificationListRef.current.scrollTop === notificationListRef.current.clientHeight;
            if (bottom && !isFetchingNextPage) {
                handleFetchNextPage();
            }
        }
    };

    useEffect(() => {
        if (notificationsData?.pages?.length > 0) {
            const flatNotifications = notificationsData?.pages.flatMap((page) => page?.data)
            setNotifications([...flatNotifications])
        }
    }, [notificationsData?.pages]);

    return (
        <VStack ref={notificationListRef} onScroll={handleScroll} h={"461px"} align="stretch" spacing="0" overflowY="auto" divider={<Divider />}>
            {notifications?.map((item, index) =>
                <NotificationItem key={index} item={item} />
            )}
            {notifications?.length === 0 && !isFetchingNextPage && <NoResults emptyMessage='No notifications found' />}
            <CircularLoading loading={isFetchingNextPage} />
        </VStack>
    )
}

export default Notifications