import React from "react";
import { useParams } from "react-router";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
  VStack,
  useColorMode,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useUserDetail } from "../../../config/query/usersQuery";
import UserBookings from "./UserBookings";

const UserDetails = () => {
  const [isSmallerThan1280] = useMediaQuery("(min-width: 1600px)");
  const { userId } = useParams();
  const { colorMode } = useColorMode();

  const userQuery = useUserDetail(userId);

  return (
    <VStack w="full" spacing={5}>
      <Box w="full">
        <Heading fontSize={16}>
          User Detail / {userQuery?.data?.firstName} {userQuery?.data?.lastName}
        </Heading>
        <Card mt={3}>
          <CardBody>
            <Stack direction={{ base: "column", lg: "row" }} width="100%">
              <Stack
                spacing={0}
                direction={{ base: "column", xl: "row" }}
                height="100%"
                width="100%"
                minHeight="140px"
              >
                <Flex justifyContent="center">
                  <Avatar
                    size="2xl"
                    w="150px"
                    h="150px"
                    bg={getColor(colorKeys.avatarBg, colorMode)}
                    name={`${userQuery?.data?.firstName} ${userQuery?.data?.lastName}`}
                    src={userQuery?.data?.profilePictureUrl}
                  />
                </Flex>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Heading fontSize={isSmallerThan1280 ? 20 : 15}>
                      Name
                    </Heading>
                    <Text pt={2} mb={3} fontSize={isSmallerThan1280 ? 20 : 15}>
                      {`${userQuery?.data?.firstName} ${userQuery?.data?.lastName}` || "N/A"}
                    </Text>
                  </Box>
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Heading fontSize={isSmallerThan1280 ? 20 : 15}>
                      Phone Number
                    </Heading>
                    <Text pt={2} mb={3} fontSize={isSmallerThan1280 ? 20 : 15}>
                      {userQuery?.data?.phone || "N/A"}
                    </Text>
                  </Box>
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p="10px"
                >
                  <Box>
                    <Heading fontSize={isSmallerThan1280 ? 20 : 15}>
                      Email Address
                    </Heading>
                    <Text pt={2} mb={3} fontSize={isSmallerThan1280 ? 20 : 15}>
                      {userQuery?.data?.email || "N/A"}
                    </Text>
                  </Box>
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Heading fontSize={isSmallerThan1280 ? 20 : 15}>
                      City
                    </Heading>
                    <Text pt={2} mb={3} fontSize={isSmallerThan1280 ? 20 : 15}>
                      {userQuery?.data?.city || "N/A"}
                    </Text>
                  </Box>
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Heading fontSize={isSmallerThan1280 ? 20 : 15}>
                      Status
                    </Heading>
                    <Text pt={2} mb={3} fontSize={isSmallerThan1280 ? 20 : 15}>
                      {userQuery?.data?.status?.name}
                    </Text>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </CardBody>
        </Card>
      </Box>

      <UserBookings />
    </VStack>
  );
};

export default UserDetails;
