import React, { useMemo } from "react";
import {
  Box,
  SimpleGrid,
  chakra,
  Text,
  Flex,
  useColorMode,
  HStack,
  Icon,
  Card,
  CardBody,
  Avatar,
  Input,
  Button,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import { useDashboard, useDashboardStats } from "../../../config/query/dashboardQuery";
import EarningChart from "./EarningChart";
import { useState } from "react";
import moment from "moment";
import { VIEW_MODE, VIEW_MODES } from "../../../config/constants/enums";
import { formatAmount, formatPercentage } from "../../../config/helpers/stringHelper";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import { useSelector } from "react-redux";

const Analytics = () => {
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const { colorMode } = useColorMode();

  const [query, setQuery] = useState({
    // FromDate: truncateTime(moment().subtract(1, "month").toISOString()),
    // ToDate: truncateTime(moment().toISOString()),
    ZoneId: null,
    ViewMode: VIEW_MODES.Hourly
  })

  const statsQuery = useMemo(() => {
    return {
      FromDateTime: query?.FromDate ? moment(query?.FromDate).toISOString() : null,
      TillDateTime: query?.ToDate ? moment(query?.ToDate).toISOString() : null
    }
  }, [query])

  const dashboardQuery = useDashboard();
  const dashboardStats = useDashboardStats(statsQuery)


  const summaryData = [
    {
      icon: APP_ICONS.PERSON_PLUS,
      title: "Users",
      value: dashboardStats?.data?.users?.totalCorporateCustomers,
      bottomValue: formatPercentage(dashboardStats?.data?.users?.dailyChange),
      iconsA: APP_ICONS.UP,
    },
    {
      icon: APP_ICONS.CALENDAR,
      title: "Bookings",
      value: dashboardStats?.data?.bookings?.totalBookings,
      bottomValue: formatPercentage(dashboardStats?.data?.bookings?.dailyChange),
      iconsA: APP_ICONS.DOWN,
    },
    {
      icon: APP_ICONS.SPENT_TIME,
      title: "All Time Spent",
      bottomValue: `${formatAmount(dashboardStats?.data?.allTimeSpent)}`,
    },
  ];
  return (
    <Box>
      <Box w="full">
        <Card mb={3} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CardBody>
            <Flex flexWrap={"wrap"} align="center" justify={"space-between"}>
              <Flex align="center">
                <Avatar
                  size="md"
                  w="50px"
                  h="50px"
                  name={dashboardQuery?.data?.name}
                  src={dashboardQuery?.data?.profilePictureUrl}
                />
                <Box ml="3">
                  <Text
                    fontSize="13px"
                    color={getColor(colorKeys.secondaryText, colorMode)}
                  >
                    Welcome to {tenant?.name} dashboard
                  </Text>
                  <Text fontWeight={"bold"} fontSize="16px">
                    {dashboardQuery?.data?.name || "N/A"}
                  </Text>
                  <Text
                    fontSize={"13px"}
                    color={getColor(colorKeys.lightBlue, colorMode)}
                  >
                    {dashboardQuery?.data?.id || "N/A"}
                  </Text>
                </Box>
              </Flex>
              <HStack flexWrap={"wrap"}>
                <HStack px={2} border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`}>
                  <Text fontSize="13px">From Date:</Text>
                  <Input onKeyDown={(e) => e.preventDefault()} border="none" maxW="150px" size="sm" type="date" value={query?.FromDate} onChange={(e) => setQuery({ ...query, FromDate: e.target.value })} />
                </HStack>
                <HStack px={2} border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`}>
                  <Text fontSize="13px">To Date:</Text>
                  <Input onKeyDown={(e) => e.preventDefault()} border="none" maxW="150px" size="sm" type="date" value={query?.ToDate} onChange={(e) => setQuery({ ...query, ToDate: e.target.value })} />
                </HStack>
                {makeSelectList(VIEW_MODE)?.map((item, index) =>
                  <Button
                    colorScheme={Number(item.value) === Number(query?.ViewMode) ? "blue" : "gray"}
                    size="sm"
                    key={index}
                    onClick={() => setQuery({ ...query, ViewMode: Number(item.value) })}
                  >
                    {item.label}
                  </Button>
                )}
              </HStack>
            </Flex>

          </CardBody>
        </Card>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} spacing={8} mt={5}>
        {summaryData?.map((item, i) => (
          <Card key={i} bg={getColor(colorKeys.tableBackground, colorMode)}>
            <CardBody>
              <HStack key={i}>
                <Flex flexDir="column" p="5px 0px 5px 0px" w="full">
                  <Flex mb={4} align={"center"}>
                    <Flex
                      mr="3"
                      align="center"
                      justify={"center"}
                      backgroundColor={getColor(
                        colorKeys.lighterBlue,
                        colorMode
                      )}
                      h="30px"
                      w="30px"
                      rounded={"full"}
                    >
                      <Icon
                        as={item.icon}
                        color={getColor(colorKeys.lightBlue, colorMode)}
                      />
                    </Flex>
                    <chakra.p>{item?.title}</chakra.p>
                  </Flex>
                  <Flex justify={"space-between"} align={"center"} w="full">
                    <Text fontSize="20px">{item.value}</Text>
                    <Flex>
                      {item.iconsA && (
                        <Icon
                          as={item.iconsA}
                          boxSize={6}
                          color={
                            i === 0
                              ? getColor(colorKeys.green, colorMode)
                              : getColor(colorKeys.red, colorMode)
                          }
                        />
                      )}
                      <Text>{item.bottomValue}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </HStack>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1 }} spacing={8} mt={5}>
        <EarningChart query={query} setQuery={setQuery} />
      </SimpleGrid>
    </Box>
  );
};

export default Analytics;
