import {
  chakra,
  Flex,
  Button,
  Box,
  Text,
  useColorMode,
  SimpleGrid,
  Image,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import FormInput from "../../../components/Forms/FormInput";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { addUser } from "../../../config/redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLogin } from "../../../config/query/authQuery";
import IMAGES from "../../../config/constants/images";
import APP_ICONS from "../../../config/constants/icons";
import COLOR_MODES from "../../../config/constants/colorModes";
import WhiteLogo from "../../../components/SVGComponents/WhiteLogo";
import FormCheckbox from "../../../components/Forms/FormCheckbox";

const Login = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant?.tenant)
  const { colorMode } = useColorMode();

  const loginQuery = useLogin();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    const payload = { ...values }
    payload.fcmToken = localStorage.getItem('pushToken') || "fcm"
    loginQuery
      .mutateAsync(payload)
      .then((response) => {
        let user = { ...response };
        let token = user?.token;
        delete user?.message;
        delete user?.token;
        dispatch(addUser({ user, token }));
      })
      .catch((error) => console.error(error));
  }

  return (
    <Flex
      height={"100vh"}
      justify="center"
      align="center"
      bg={getColor(colorKeys.white, colorMode)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full" w="full">
        <Box
          h="full"
          background={`linear-gradient(90deg, rgba(43,61,79,0.61) 0%, rgba(43,61,79,0.61) 100%), url(${IMAGES.AUTH_HERO})`}
          display={{ base: "none", md: "block" }}
        />

        <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
          <Box w="full" maxW="480px" px="5">
            <Box w="110px" m="auto">
              <Image _dark={{
                filter: "brightness(0) invert(1)"
              }} w="full" h="full" src={tenant?.logoUrl} fallbackSrc={IMAGES.LOGO} alt="logo" />
            </Box>

            <Box>
              <Text
                fontSize="30px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={5}
              >
                Welcome to {tenant?.name}
              </Text>
              <Text
                fontSize="18px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={1}
              >
                Hello! Lets Get Started
              </Text>
            </Box>

            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">
              <FormInput
                id={"email"}
                label={"Email"}
                placeholder="Enter Email Address"
                required={true}
                errors={errors}
                control={control}
                inputProps={{ size: "lg", fontSize: "15px" }}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}
                hideLabel={true}
              />

              <FormInput
                id={"password"}
                label={"Password"}
                type="password"
                secure={true}
                placeholder="Enter Password"
                required={true}
                errors={errors}
                control={control}
                inputProps={{ size: "lg", fontSize: "15px" }}
                containerStyles={{ mt: 5 }}
                hideLabel={true}
              />
              <FormCheckbox
                control={control}
                id={"rememberMe"}
                options={["Remember Me"]}
                optionLabelProps={{ fontSize: "14px" }}
                containerProps={{ mt: 5 }}
              />

              <PrimaryButton
                isLoading={loginQuery.isLoading}
                mt={5}
                type="submit"
                p="18px 33px"
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                w="full"
                h="50px"
                rounded="md"
                fontWeight={"light"}
                color="white"
              >
                Login
              </PrimaryButton>
            </chakra.form>
          </Box>

          <Box
            fontSize="15px"
            color={getColor(colorKeys.primaryText, colorMode)}
            textAlign="center"
          >
            <Flex textAlign={"center"} justify="center">
              <Link to="/auth/forgot-password">
                <Button
                  display={"flex"}
                  align="center"
                  variant="link"
                  type="button"
                  fontWeight={"400"}
                  color={getColor(colorKeys.secondaryText, colorMode)}
                  leftIcon={<Icon as={APP_ICONS.LOCK} />}
                >
                  Forgot your password?
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default Login;
