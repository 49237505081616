import moment from "moment/moment";
import momentTimeZone from "moment-timezone";
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "../constants";
import { VIEW_MODES } from "../constants/enums";
import { TIME_ZONES } from "../constants/date";

const getTimeZone = () => {
  return momentTimeZone.tz.guess();
};

const formatDate = (date) => {
  if (!date) return "N/A"
  return moment(date).tz(getTimeZone()).format(DEFAULT_DATE_FORMAT);
};

const formatTime = (time) => {
  return moment(time);
};

const formatDateTime = (dateTime) => {
  if (dateTime)
    return moment(dateTime).utc().local().format(DEFAULT_DATETIME_FORMAT);
  else return "N/A";
};

const formatDateTimeFromNow = (dateTime) => {
  return momentTimeZone(dateTime).tz(getTimeZone()).fromNow();
};

const formatDateTimeForMessage = (dateTime) => {
  if (!dateTime) return moment().utc().format("DD MMM yyyy hh:mm:ss.SSS A");
  return moment(dateTime).utc().format("DD MMM yyyy hh:mm:ss.SSS A");
};

const formatMessageTime = (dateTime) => {
  if (moment(dateTime).isSame(moment(), "day")) {
    return moment(dateTime).tz(getTimeZone()).format("hh:mm A");
  } else if (moment(dateTime).isSame(moment().subtract(1, "days"), "day"))
    return (
      "Yesterday at " + moment(dateTime).tz(getTimeZone()).format("hh:mm A")
    );
  else return moment(dateTime).tz(getTimeZone()).format("DD MMM yyyy hh:mm A");
};

const formatDateForInput = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const truncateTime = (dateTime) => {
  return moment(dateTime).format("YYYY-MM-DD");
}

export const formatTimeByViewMode = ({ viewMode, time }) => {
  switch (Number(viewMode)) {
    case VIEW_MODES.Hourly:
      return moment(time).format("hh:mm A")
    case VIEW_MODES.Daily:
      return moment(time).format("DD/MMM/YYYY")
    case VIEW_MODES.Weekly:
      return moment(time).format("DD/MMM/YYYY")
    case VIEW_MODES.Monthly:
      return moment(time).format("MMM/YYYY")
    default:
      return moment(time).format("hh:mm A")
  }
}


export const formatDateTimeWithoutConvertingToUtc = (date) => {
  // Check if date is a valid Moment object
  if (moment.isMoment(date)) {
    // Use the local format string for the server's locale
    return date.format('YYYY-MM-DD HH:mm:ss');  // Adjust format as needed
  } else {
    // Try parsing the date string if not a Moment object
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      // Use local format after parsing
      return parsedDate.format('YYYY-MM-DD HH:mm:ss');  // Adjust format as needed
    } else {
      return ''
    }
  }
};

export const formatDateTimeToZone = (dateTime, zone = 0, format = DEFAULT_DATETIME_FORMAT) => {
  if (!dateTime) return "N/A"
  const zoneTime = momentTimeZone(dateTime).utcOffset(zone / 60).format(format)
  if (zoneTime === "Invalid date") return "N/A"
  return `${zoneTime} ${TIME_ZONES[zone / 60]})`
}


export {
  formatDate,
  formatTime,
  formatDateTime,
  formatDateTimeFromNow,
  formatDateTimeForMessage,
  formatMessageTime,
  formatDateForInput,
};
