import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  HStack,
  useColorMode,
  IconButton,
  chakra,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/Forms/FormInput";
import LocationSearchSelect from "../../../../components/Maps/LocationSearchSelect";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import APP_ICONS from "../../../../config/constants/icons";
import FormSwitch from "../../../../components/Forms/FormSwitch";
import { useSearchVehicleType } from "../../../../config/query/bookingQuery";
import { RideType } from "../../../../config/constants/enums";
import { generateString } from "../../../../config/helpers/stringHelper";

const BookingInfo = ({ control, errors, watch, setValue }) => {
  const { colorMode } = useColorMode();
  const [seasons, setSeasons] = useState([]);

  const searchVehicleType = useSearchVehicleType();
  const {
    stops,
    rideType,
    rideTime,
    pickup, destination,
    vehicleTypeId,
    userId,
    corporateId,
    paymentMethod,
    promoCodeId,
    customerType,
    quotedFare
  } = watch();

  function processResponseData(resdata) {
    return resdata.reduce((accumulator, quotation) => {
      const vehicles = quotation.vehicles || [];
      accumulator.push(...vehicles);
      return accumulator;
    }, []);
  }

  useEffect(() => {
    // setValue("vehicleTypeId", null);
    const isAllDefined =
      typeof rideType !== "undefined" &&
      (rideType ? typeof rideTime !== "undefined" : true) &&
      typeof pickup !== "undefined" &&
      typeof destination !== "undefined";

    if (isAllDefined) {
      const defaultRideType = rideType ? RideType.SCHEDULED : RideType.NOW;
      const defaultRideTime =
        typeof rideTime !== "undefined"
          ? new Date(rideTime).toISOString()
          : new Date().toISOString();

      const rideStops = [
        pickup.latLng,
        ...(stops?.length > 0 ? stops.map((stop) => stop.latLng) : []),
        destination.latLng,
      ].filter((stop) => typeof stop !== "undefined" && stop !== null);

      const value = {
        rideType: defaultRideType,
        rideTime: defaultRideTime,
        rideStops: rideStops,
        customerId: userId,
        promoCodeId,
      };

      let isMounted = true; // To handle cleanup if component unmounts

      const formMutate = searchVehicleType.mutateAsync;

      formMutate(value)
        .then((res) => {
          if (isMounted) {
            const resdata = res.vehicleQuotations || [];

            // Check if resdata is an array and not empty
            if (Array.isArray(resdata) && resdata.length > 0) {
              const allVehicles = processResponseData(resdata);
              setSeasons(allVehicles);
            }
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.error("Error while creating vehicle class:", err);
          }
        });

      // Cleanup function to cancel any pending requests if the component unmounts
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(stops),
    rideType,
    rideTime,
    pickup,
    destination,
    userId,
    corporateId,
    paymentMethod,
    promoCodeId,
    customerType,
  ]);

  const addStop = () => {
    if (stops?.length >= 3) return
    const newStops = [
      ...stops,
      {
        address: "",
        latitute: null,
        longitude: null,
        tempId: generateString(10),
      },
    ];
    setValue("stops", newStops);
  };
  const removeStop = (id) => {
    const newStops = stops.filter((item) => item.tempId !== id);
    setValue("stops", newStops);
  };

  const renderVehicleTypes = (vehicleTypes) => {
    return vehicleTypes?.map((item, index) => {
      if (
        vehicleTypeId === item.vehicleTypeId &&
        quotedFare !== item.fare
      ) {
        setValue("quotedFare", item.fare);
      }
      return (
        <Box
          key={index}
          maxW="sm"
          m="1"
          borderWidth="1px"
          borderRightWidth="3px"
          borderTopWidth="3px"
          borderTopColor={
            vehicleTypeId == item.vehicleTypeId
              ? getColor(colorKeys.dimBlue, colorMode)
              : ""
          }
          borderRightColor={
            vehicleTypeId == item.vehicleTypeId
              ? getColor(colorKeys.dimBlue, colorMode)
              : ""
          }
          borderRadius="lg"
          overflow="hidden"
          onClick={() => {
            setValue("vehicleTypeId", item.vehicleTypeId)
            setValue("quotedFare", item.fare)
          }}
        >
          <Box m="4">
            <chakra.p>{item.vehicleType}</chakra.p>
            <chakra.p
              color={getColor(colorKeys.dimBlue, colorMode)}
              fontSize="13px"
            >
              The Closest Driver is approximately 4 mins away
            </chakra.p>
            <HStack spacing={3}>
              <Image w="100%" src={item.imageURL} />
            </HStack>
            <HStack justify="space-between">
              <HStack spacing={1}>
                <Icon as={APP_ICONS.USERS} />
                <chakra.span>{item.seatingCapacity}</chakra.span>
                <Icon as={APP_ICONS.DRIVERS} />
                <chakra.span>{item.seatingCapacity}</chakra.span>
              </HStack>
              <HStack justify="space-between">
                <HStack spacing={1}>
                  <Icon as={APP_ICONS.USERS} />
                  <chakra.span>{item.seatingCapacity}</chakra.span>
                  <Icon as={APP_ICONS.DRIVERS} />
                  <chakra.span>{item.seatingCapacity}</chakra.span>
                </HStack>
                <HStack spacing={0}>
                  <Icon as={APP_ICONS.DOLLAR_SIGN} />
                  <chakra.span>{item.fare}</chakra.span>
                </HStack>
              </HStack>
            </HStack>
          </Box>
        </Box>
      )
    })
  };

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Booking Info</Heading>
      </CardHeader>

      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)} py="5">
        <VStack spacing={5} align="stretch">
          <Flex>
            <Text fontSize="14px" pt="3px">
              Now
            </Text>
            <FormSwitch
              control={control}
              errors={errors}
              id="rideType"
              containerProps={{
                mx: 3,
                w: "fit-content",
              }}
            />
            <Text fontSize="14px" pt="4px">
              Scheduled
            </Text>
          </Flex>

          {rideType && (
            <FormInput
              id="rideTime"
              label={"Starting Date & Time"}
              control={control}
              errors={errors}
              required={rideType}
              type={"datetime-local"}
              inputProps={{ size: "sm" }}
            />
          )}

          <LocationSearchSelect
            control={control}
            errors={errors}
            id={"pickup"}
            label={"Pickup Location"}
            placeholder={"Enter pickup location"}
            required={true}
            inputProps={{ size: "sm" }}
          />

          {stops?.map((item, index) => {
            return (
              <HStack spacing={2} align="end" key={index}>
                <LocationSearchSelect
                  control={control}
                  errors={errors}
                  id={`stops[${index}]`}
                  label={"Stop Location"}
                  placeholder={"Enter stop location"}
                  required={true}
                  inputProps={{ size: "sm" }}
                />
                <IconButton
                  onClick={() => removeStop(item.tempId)}
                  size="sm"
                  icon={<Icon as={APP_ICONS.CLOSE} />}
                />
              </HStack>
            );
          })}

          <Button
            size="sm"
            zIndex={0}
            onClick={addStop}
            leftIcon={<Icon as={APP_ICONS.ADD} boxSize={"5"} />}
          >
            Add More Stops
          </Button>
          <LocationSearchSelect
            control={control}
            errors={errors}
            id={"destination"}
            label={"Destination"}
            placeholder={"Enter destination"}
            required={true}
            inputProps={{ size: "sm" }}
          />

          <Box
            maxW="sm"
            // borderWidth="1px"
            overflowY="scroll"
            h="350px"
            display={seasons.length > 0 ? "" : "none"}
          >
            <FormInput
              id="vehicleTypeId"
              label={"Vehicle Type"}
              control={control}
              errors={errors}
              required={true}
              display="none"
              inputProps={{ size: "sm", display: "none" }}
            />
            {renderVehicleTypes(seasons)}
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default BookingInfo;
