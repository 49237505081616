import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

const FormRadio = ({ options, defaultValue, label, errors = {}, id, required = false, control, hideLabel, stackProps, rules, radioGroupProps }) => {
    if (required) {
        required = `${label} is required`
    }
    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                <FormControl
                    isInvalid={errors[id]}
                >
                    {(label && !hideLabel) && <FormLabel htmlFor={id} fontSize="14px">{label}</FormLabel>}
                    <RadioGroup alignItems={"end"} onChange={onChange} onBlur={onBlur} value={String(value)} defaultValue={defaultValue} ref={ref} {...radioGroupProps} {...rest}>
                        <Stack direction='row' {...stackProps}>
                            {options && options.map((item, index) =>

                                <Radio
                                    key={index}
                                    value={String(item.value) || String(item)}
                                >
                                    {item.label || item}
                                </Radio>
                            )}

                        </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                        {errors[id] && errors[id].message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}

export default FormRadio