export const ACTIONS = {
  EDIT: "Edit",
  DELETE: "Delete",
  VIEW: "View",
};

export const RideType = {
  NOW: 0,
  SCHEDULED: 1,
};

export const PAYMENT_TYPE = {
  0: "Cash",
  1: "Card",
  2: "DirectInvoice",
  3: "Comp",
  4: "Corporate Card"
};

export const EXTERNAL_WALLET_TYPE = {
  0: "ApplePay",
  1: "GooglePay",
};

export const PAYMENT_TYPE_LABELS = {
  Cash: 0,
  Card: 1,
}

export const RIDE_STATUSES = {
  Cancelled: 0,
  WaitingForAccept: 1,
  Accepted: 2,
  DriverOnTheWay: 3,
  Arrived: 4,
  InTransit: 5,
  Reached: 6,
  Completed: 7,
  NoShows: 8,
  Incidents: 9,
  ParkingLot: 10,
};

//will be used in booking listing
export const RIDE_STATUS = {
  0: "Cancelled",
  1: "Dispatching",
  2: "Accepted",
  3: "Driver On The Way",
  4: "Arrived and Waiting",
  5: "P.O.B",
  6: "Dropped Off",
  7: "Completed",
  10: "Parking Lot",
};

export const USER_STATUS = {
  0: "Pending",
  1: "Active",
  2: "Inactive",
};

export const USER_STATUSES = {
  Pending: 0,
  Active: 1,
  Blocked: 2,
};

export const VIEW_MODE = {
  0: "Hourly",
  1: "Daily",
  2: "Weekly",
  3: "Monthly",
}

export const VIEW_MODES = {
  Hourly: 0,
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
}

export const BOOKINGS_STATUSES = {
  Active: 0,
  Scheduled: 1,
  Finished: 2,
};

export const STRIPE_STATUS = {
  0: "Pay By Card",
  1: "Direct Invoice"
}

export const STRIPE_STATUSES = {
  Card: 0,
  DirectInvoice: 1
}