import { Box, useDisclosure, useColorMode } from "@chakra-ui/react";
import React from "react";
import Sider from "./Sider";
import Header from "./Header";
import { Navigate, Outlet } from "react-router";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import { useSelector } from "react-redux";

const AdminLayout = () => {
  const sidebar = useDisclosure();
  const desktopDisclosure = useDisclosure({ defaultIsOpen: false });
  const token = useSelector((state) => state.user?.token);
  const { colorMode } = useColorMode();

  if (!token) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  return (
    <Box as="section" minH="100vh">
      <Sider disclosure={sidebar} desktopDisclosure={desktopDisclosure} />
      <Box
        ml={{
          base: 0,
          md: desktopDisclosure.isOpen ? "70px" : "255px",
        }}
        transition=".3s ease"
      >
        <Header disclosure={sidebar} desktopDisclosure={desktopDisclosure} />
        <Box
          as="main"
          p="15px"
          bg={getColor(colorKeys.layoutBoxBackground, colorMode)}
          minH="calc(100vh - 70px)"
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLayout;
