import React from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
  useColorMode,
  Image,
} from "@chakra-ui/react";

import { colorKeys, getColor } from "../../../../config/constants/appColors";
import PolygonBox from "../../../../components/Maps/PolygonBox";
import PickUpLocationIcon from "../../../../assets/icons/PickUpLocationIcon.svg";
import DropOffLocationIcon from "../../../../assets/icons/DropOffLocationIcon.svg";
import StopsLocationSvg from "../../../../assets/icons/StopsLocationSvg.svg";

const TripData = ({ data }) => {
  const { colorMode } = useColorMode();

  const getTripData = (index, arr) => {
    const icon =
      index === 0
        ? PickUpLocationIcon
        : index === arr.length - 1
        ? DropOffLocationIcon
        : StopsLocationSvg;
    const color =
      index === 0
        ? colorKeys.primaryButtonFill
        : index === arr.length - 1
        ? colorKeys.primaryButtonFill
        : colorKeys.gray;
    const label =
      index === 0 ? "Pickup" : index === arr.length - 1 ? "Dropoff" : "Stop";
    return {
      icon,
      color,
      label,
    };
  };

  // Custom sorting function
  function sortByCoordinates(a, b) {
    // Compare latitude values first
    if (a.lat < b.lat) return -1;
    if (a.lat > b.lat) return 1;

    // If latitudes are the same, compare longitude values
    if (a.lng < b.lng) return -1;
    if (a.lng > b.lng) return 1;

    // If both latitudes and longitudes are equal, consider them equal
    return 0;
  }
  const routeDetail = data?.routeDetail?.sort(sortByCoordinates);

  return (
    <Box w="full">
      <Heading fontSize="16px">Destination</Heading>
      <Card w="full" mt={5}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <Flex flexWrap={"wrap"} w="full" justify={"space-between"}>
            <Box maxW={{ base: "full", md: "50%" }}>
              {data?.rideStops?.map((item, index, arr) => {
                const { icon, color, label } = getTripData(index, arr);
                return (
                  <Box key={index} p="5px 10px" minW="250px">
                    <Flex>
                      <Image w={22} src={icon} mt={-27} />
                      <Box>
                        <Heading
                          color={getColor(color, colorMode)}
                          fontWeight={"500"}
                          fontSize={"13px"}
                        >
                          {label}
                        </Heading>
                        <Text
                          {...(index !== arr.length - 1 && {
                            borderLeft: `1px dashed ${getColor(
                              colorKeys.gray,
                              colorMode
                            )}`,
                            pos: "relative",
                            left: "-14px",
                            pl: "17px",
                          })}
                          mt={2}
                          fontSize="13px"
                          pb={0}
                        >
                          {item?.address}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
            <Box minH="450px" minW={{ base: "100%", md: "50%" }}>
              <PolygonBox
                disabled={true}
                polylineCoords={routeDetail || []}
                polylineLastCoords={
                  data?.rideStops[data?.rideStops.length - 1]?.location
                }
                center={routeDetail?.at(0)}
                markers={data?.rideStops}
                setPolylineCoords={() => true}
                isPolyline={true}
              />
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};

export default TripData;
