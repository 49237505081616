import React, { useState } from "react";
import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useBookings } from "../../../config/query/bookingQuery";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import CustomTable from "../../../components/BasicUI/CustomTable";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import TripBox from "../../../components/BasicUI/DataBoxes/TripBox";
import { formatDateTime, formatDateTimeFromNow } from "../../../config/helpers/dateHelper";
import APP_ICONS from "../../../config/constants/icons";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import useDebounce from "../../../config/hooks/useDebounce";
import sortOrders from "../../../config/constants/sortOrders";
import { Link } from "react-router-dom";
import { breakWithCaps } from "../../../config/helpers/stringHelper";

const Bookings = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState({
    PageNumber: 1,
    PageSize: 20,
    Sort: "lastModifiedOn",
    SortOrder: sortOrders.DESC,
  });
  const debouncedQuery = useDebounce(query);

  const bookingQuery = useBookings(debouncedQuery);

  const onView = (item) => {
    navigate(`/admin/bookings/${item.id}`);
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={"26px"} fontWeight="bold">
          Bookings
        </Heading>
        <PrimaryButton
          onClick={() => {
            navigate("/admin/bookings/create");
          }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Booking
        </PrimaryButton>
      </Flex>

      <CustomTable
        searchPlaceholder="Search bookings"
        head={[
          {
            title: "Booking",
            extractor: "id",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover
                data={item}
                titleKey={"id"}
                fallBackText={"N/A"}
                triggerProps={{
                  as: Link,
                  to: `/admin/bookings/${item.id}`
                }}
              >
                <LabelValuePair
                  label="Status"
                  value={true}
                  valueComponent={
                    <StatusBadge
                      bookingStatus={true}
                      value={item?.status?.id}
                    />
                  }
                />
                <TripBox
                  trip={[
                    {
                      label: "Pickup From",
                      value: item.pickupAddress,
                    },
                    {
                      label: "Drop-off",
                      value: item.dropoffAddress,
                    },
                  ]}
                />
                <LabelValuePair
                  label="Created"
                  value={formatDateTime(item.createdBy?.dateTime)}
                />
                <LabelValuePair
                  label="Last Updated"
                  value={formatDateTime(item.updateBy?.dateTime)}
                />
              </TableInfoPopover>
            ),
          },
          {
            title: "Name",
            extractor: "passengerName",
            align: "left",
            isSortable: true,
          },
          {
            title: "Ride Time",
            extractor: "rideTime",
            align: "left",
            isSortable: true,
            component: (item) => formatDateTime(item.rideTime),
          },
          {
            title: "Pickup / Dropoff Time",
            extractor: "pickup",
            align: "left",
            isSortable: true,
            component: (item) => <Box>
              {(!item.pickup && !item.dropoff) && "N/A"}
              {item.pickup && <Text fontSize="14px">{formatDateTime(item.pickup)}</Text>}
              {item.dropoff && <Text fontSize="14px">{formatDateTime(item.dropoff)}</Text>}
            </Box>
          },
          {
            title: "Pickup Location",
            extractor: "pickupAddress",
            align: "left",
            isSortable: true,
            component: (item) => (
              <BreakText value={item.pickupAddress} fontSize={"14px"} />
            ),
          },
          {
            title: "Dropoff Location ",
            extractor: "dropoffAddress",
            align: "left",
            isSortable: true,
            component: (item) => (
              <BreakText value={item.dropoffAddress} fontSize={"14px"} />
            ),
          },
          {
            title: "Paid By",
            extractor: "paymentMethod",
            align: "left",
            component: (item) => breakWithCaps(item.paymentMethod?.name)
          },
          {
            title: "Status",
            extractor: "status",
            component: (item) => (
              <StatusBadge value={item.status?.id} bookingStatus={true} />
            ),
            isSortable: true,
            align: "center",
          },
          { title: "Last Modified", extractor: "lastModifiedOn", align: "center", isSortable: true, component: (item) => formatDateTimeFromNow(item.lastModifiedOn) },
          { title: "Actions", extractor: "actions", align: "center" },
        ]}
        data={bookingQuery?.data?.data}
        loading={bookingQuery?.isLoading}
        totalResults={bookingQuery?.data?.meta?.totalCount}
        totalPages={bookingQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => bookingQuery.refetch()}
        isRefreshing={bookingQuery?.isFetching}
        onView={onView}
      />
    </Box>
  );
};

export default Bookings;
