import React, { useState } from "react";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import FormModal from "../../../components/Forms/FormModal";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../../config";
import { customStripeTheme } from "../../../config/constants/stripe";
import StripeConnect from "../../../components/Forms/Payments/StripeConnect";
import { useSetupIntent } from "../../../config/query/profileQuery";

const stripePromise = loadStripe(Config.env().STRIPE_PUBLISHABLE_KEY);

const Wrapper = (props) => (
  <Elements
    stripe={stripePromise}
    options={{
      mode: "setup",
      currency: "usd",
      appearance: customStripeTheme,
      payment_method_types: ["card"],
    }}
  >
    <CardForm {...props} />
  </Elements>
);

const CardForm = ({ disclosure, onSuccess }) => {
  const useSetupIntentQuery = useSetupIntent();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0);

  const handleError = () => {
    setLoading(false);
  };
  const handleSubmit = async (event) => {
    setCount(count + 1);
    event.preventDefault();
    if (!stripe) {
      return;
    }

    setLoading(true);
    await elements.submit();
    useSetupIntentQuery
      .mutateAsync()
      .then((response) => {
        stripe
          .confirmSetup({
            elements,
            clientSecret: response?.clientSecret,
            redirect: "if_required",
          })
          .then((res) => {
            if (res?.error) {
              handleError(res?.error);
            } else {
              onSuccess && onSuccess();
              setLoading(false);
              disclosure?.onClose();
            }
          });
      })
      .catch((error) => console.warn(error));
  };

  return (
    <FormModal
      title={"Add Card"}
      disclosure={disclosure}
      onSubmit={handleSubmit}
      maxW={"50rem"}
      onCloseFn={() => { }}
      containerProps={{ as: "form" }}
      isSubmitting={loading || useSetupIntentQuery.isLoading}
      disabled={!stripe || loading || useSetupIntentQuery.isLoading}
    >
      <StripeConnect />
    </FormModal>
  );
};

export default Wrapper;
