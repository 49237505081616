import {
  chakra,
  Flex,
  Box,
  Text,
  useColorMode,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";

import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import IMAGES from "../../../config/constants/images";

import { addTempToken } from "../../../config/redux/slices/userSlice";
import { useVerifyOtp } from "../../../config/query/authQuery";
import FormPinInput from "../../../components/Forms/FormPinInput";
import { OTP_LENGTH } from "../../../config/constants/settings";

const Verify = () => {
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const { reset } = useSelector((state) => state.user);
  const verifyOtpQuery = useVerifyOtp();

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    verifyOtpQuery
      .mutateAsync({ ...values, email: reset?.tempEmail })
      .then((response) => {
        resetForm();
        dispatch(addTempToken(response.token));
        navigate(`/auth/reset-password`);
      })
      .catch((error) => {
        setValue("token", "");
        console.error(error);
      });
  }
  if (!reset?.tempEmail) {
    return <Navigate to="/auth/forgot-password" />;
  }
  return (
    <Flex
      height={"100vh"}
      justify="center"
      align="center"
      bg={getColor(colorKeys.white, colorMode)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full" w="full">
        <Box
          h="full"
          background={`linear-gradient(90deg, rgba(43,61,79,0.61) 0%, rgba(43,61,79,0.61) 100%), url(${IMAGES.AUTH_HERO})`}
          display={{ base: "none", md: "block" }}
        />

        <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
          <Box w="full" maxW="500px">
            <Box w="110px" m="auto">
              <Image w="full" h="full" src={tenant?.logoUrl} fallbackSrc={IMAGES.LOGO} alt="logo" />
            </Box>

            <Box>
              <Text
                fontSize="30px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={5}
              >
                Reset Your Email
              </Text>
              <Text
                fontSize="18px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={1}
              >
                Reset Your Password via Email
              </Text>
            </Box>
            <Flex
              mt={12}
              borderRadius={"5px"}
              justify={"center"}
              align={"center"}
              bg={getColor(colorKeys.purpleBlue, colorMode)}
              height={"55px"}
            >
              <Text
                fontSize="13px"
                textAlign="center"
                lineHeight={"19px"}
                fontWeight={"400"}
                color={getColor(colorKeys.white, colorMode)}
              >
                Please enter the {OTP_LENGTH}-digit code sent to {reset?.tempEmail}
              </Text>
            </Flex>

            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">
              <FormPinInput
                control={control}
                id={"token"}
                count={OTP_LENGTH}
                errors={errors}
                rules={{
                  required: "OTP is required",
                  minLength: {
                    value: OTP_LENGTH,
                    message: `Minimum length should be ${OTP_LENGTH}`,
                  },
                  maxLength: {
                    value: OTP_LENGTH,
                    message: `Maximum length should be ${OTP_LENGTH}`,
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Only numbers are allowed",
                  },
                }}

              />

              <PrimaryButton
                isLoading={verifyOtpQuery.isLoading}
                mt={5}
                type="submit"
                p="18px 33px"
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                w="full"
                h="50px"
                rounded="md"
                fontWeight={"light"}
                color="white"
              >
                Confirm
              </PrimaryButton>
            </chakra.form>
          </Box>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default Verify;
