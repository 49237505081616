import React from 'react'
import { chakra } from "@chakra-ui/react"

const BreakText = ({ value, fallBack = "N/A", ...rest }) => {
    return (
        <chakra.span
            maxWidth="275px"
            whiteSpace="break-spaces"
            display="block"
            {...rest}
        >{value || fallBack}</chakra.span>
    )
}

export default BreakText