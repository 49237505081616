import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Box, Heading, Flex, Tooltip } from "@chakra-ui/react";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import CustomTable from "../../../components/BasicUI/CustomTable";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import { formatDateTime } from "../../../config/helpers/dateHelper";
import {
  useUsers,
  useDeleteUser,
  useActivateCustomer,
  useBlockCustomer,
  useUpdateCorporatePay,
} from "../../../config/query/usersQuery";
import { getFilters } from "../../../config/helpers/filterHelper";
import useDebounce from "../../../config/hooks/useDebounce";
import MenuSelect from "../../../components/Forms/MenuSelect";
import { getDisabledUserStatus, makeSelectList } from "../../../config/helpers/selectListHelper";
import { USER_STATUS, USER_STATUSES } from "../../../config/constants/enums";
import sortOrders from "../../../config/constants/sortOrders";
import StatusSwitch from "../../../components/BasicUI/DataBoxes/StatusSwitch";

const Users = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState({
    PageNumber: 1,
    PageSize: 20,
    Sort: "status",
    SortOrder: sortOrders.ASC
  });
  const debouncedQuery = useDebounce(query);
  const usersQuery = useUsers(debouncedQuery);

  const deleteUserQuery = useDeleteUser();
  const activateCustomer = useActivateCustomer();
  const blockCustomer = useBlockCustomer();
  const corporatePay = useUpdateCorporatePay()

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const onDelete = (id) => {
    deleteUserQuery
      .mutateAsync(id)
      .then(() => usersQuery.refetch())
      .catch((error) => console.error(error));
  };

  const onView = (item) => {
    navigate(`/admin/users/${item.customerId}`);
  };

  const handleStatusChange = (status, customerId) => {
    if (status?.value === USER_STATUSES.Active) activateCustomer.mutate(customerId);
    else blockCustomer.mutate(customerId);
  };

  const onUpdateCorporatePay = (value, customerId) => {
    corporatePay.mutate({ customerId, isCorporatePay: value })
  }

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">
          Users
        </Heading>
      </Flex>
      <CustomTable
        searchPlaceholder="Search users"
        filters={getFilters({
          CorporateCustomerStatus: Object.keys(USER_STATUS).map((item) => ({
            key: item,
            value: USER_STATUS[item],
          })),
        })}
        head={[
          {
            title: "Name",
            extractor: "name",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover data={item} triggerOnClick={() => onView(item)}>
                <LabelValuePair
                  label="Status"
                  value={true}
                  valueComponent={<StatusBadge value={item.status?.name} />}
                />
                <LabelValuePair
                  label="Created"
                  value={formatDateTime(item.createdBy?.dateTime)}
                />
                <LabelValuePair
                  label="Last Updated"
                  value={formatDateTime(item.updateBy?.dateTime)}
                />
              </TableInfoPopover>
            ),
          },
          {
            title: "Phone Number",
            extractor: "phone",
            isSortable: true,
          },
          {
            title: "Email Address",
            extractor: "email",
            isSortable: true,
          },
          { title: "City", extractor: "city", align: "left", isSortable: true },
          {
            title: "Corporate Pay",
            extractor: "isCorporatePay",
            isSortable: true,
            align: "center",
            component: (item) => {
              const isDisabled = item.status?.id === USER_STATUSES.Blocked || item.status?.id === USER_STATUSES.Pending
              return (
                <Tooltip label={isDisabled ? "Corporate pay is only available for active customers" : "Updates will apply to new rides"} hasArrow>
                  <div>
                    <StatusSwitch
                      key={item.id}
                      value={item.isCorporatePay}
                      onChange={(e) => onUpdateCorporatePay(e.target.checked, item.customerId)}
                      isDisabled={isDisabled}
                    />
                  </div>
                </Tooltip>
              )
            },
          },
          {
            title: "Status",
            extractor: "status",
            component: (item) => (
              <MenuSelect
                value={USER_STATUS[item.status?.id]}
                onChange={(status) =>
                  handleStatusChange(status, item.customerId)
                }
                options={makeSelectList(USER_STATUS)}
                disabledOptions={getDisabledUserStatus(item.status?.id)}
                hiddenOptions={[USER_STATUSES.Pending]}
                buttonProps={USER_STATUSES.Blocked === item.status?.id && {
                  colorScheme: 'red',
                  color: "#fff"
                }}
              />
            ),
            isSortable: true,
            align: "center",
          },
        ]}
        data={usersQuery?.data?.data || []}
        loading={usersQuery?.isLoading}
        totalResults={usersQuery?.data?.meta?.totalCount}
        totalPages={usersQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => usersQuery.refetch()}
        isRefreshing={usersQuery?.isFetching}
        onDelete={onDelete}
        onView={onView}
      />
    </Box>
  );
};

export default Users;
