import React, { useState, useEffect } from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, Icon, IconButton, chakra, useColorMode } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import APP_ICONS from '../../config/constants/icons';
import { accessValue } from '../../config/helpers/stringHelper';
import { Select } from 'chakra-react-select';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { colorKeys, getColor } from '../../config/constants/appColors';
import _ from 'lodash';

const LocationSearchSelect = ({ label, placeholder, errors, id, control, required = false, rules, containerStyles, onRefresh, inputProps, isLoading }) => {
    const { colorMode } = useColorMode();
    if (required) {
        required = `${label} is required`;
    }

    const [autocompleteService, setAutocompleteService] = useState(null);
    const [predictions, setPredictions] = useState([]);

    useEffect(() => {
        const autocomplete = new window.google.maps.places.AutocompleteService();
        setAutocompleteService(autocomplete);
    }, []);

    const handleSearch = _.debounce((value) => {
        if (autocompleteService) {
            autocompleteService.getPlacePredictions(
                {
                    input: value
                },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(predictions);
                    } else {
                        setPredictions([]);
                    }
                }
            );
        }
    }, 300);

    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                <Flex align="end" w="full">
                    <FormControl isInvalid={errors[id] || accessValue(errors, `${id}.message`)} {...containerStyles}>
                        <FormLabel htmlFor={id} fontSize={'13px'}>
                            {label}
                            {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                        </FormLabel>
                        <Select
                            isLoading={isLoading}
                            onInputChange={(query) => {
                                const newValue = query;
                                handleSearch(newValue);
                            }}
                            allowClear={true}
                            onChange={async (value) => {
                                const results = await geocodeByAddress(value.label);
                                const latLng = await getLatLng(results[0]);
                                onChange({ ...value, latLng })
                            }}
                            value={value}
                            ref={ref}
                            placeholder={placeholder}
                            options={predictions.map((prediction) => ({
                                value: prediction.place_id,
                                label: prediction.description
                            }))}
                            leftAddon={
                                <chakra.span
                                    fontSize="1.2em"
                                    color={getColor(colorKeys.primary, colorMode)}
                                    mr="0.5em"
                                >
                                    {APP_ICONS.SEARCH}
                                </chakra.span>
                            }
                            defaultInputValue=''
                            defaultValue={null}
                            menuPosition='fixed'
                            classNamePrefix={"location-select"}
                            {...rest}
                            {...inputProps}
                        />
                        <FormErrorMessage>{accessValue(errors, `${id}.message`)}</FormErrorMessage>
                    </FormControl>
                    {onRefresh && (
                        <IconButton onClick={onRefresh} ml="1em" aria-label="Search database" icon={<Icon boxSize={7} as={APP_ICONS.REFRESH} />} />
                    )}
                </Flex>
            )}
        />
    );
};

export default LocationSearchSelect