import React from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText'
import { useCustomerBookings, useDeleteBooking } from '../../../config/query/bookingQuery';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import useDebounce from '../../../config/hooks/useDebounce';
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge';

const UserBookings = () => {
    const navigate = useNavigate()

    const { userId } = useParams()
    const [query, setQuery] = useState({
        PageNumber: 1,
        PageSize: 20,
        CustomerId: userId
    });
    const debouncedQuery = useDebounce(query)

    const bookingQuery = useCustomerBookings(debouncedQuery);
    const deleteBookingQuery = useDeleteBooking();

    const onDelete = (id) => {
        deleteBookingQuery
            .mutateAsync(id)
            .then(() => bookingQuery.refetch())
            .catch((error) => console.error(error))
    };

    const onView = (item) => {
        navigate(`/admin/bookings/${item.id}`);
    };

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }));
    };

    return (
        <CustomTable
            containerProps={{ mt: 5, w: "full" }}
            searchPlaceholder="Search bookings"
            head={[
                {
                    title: "Booking",
                    extractor: "id",
                    align: "left",
                    isSortable: true,
                },
                {
                    title: "Pickup/ Drop Off",
                    extractor: "pickUp",
                    align: "left",
                    isSortable: true,
                },
                {
                    title: "Pickup Location",
                    extractor: "pickupAddress",
                    align: "left",
                    isSortable: true,
                    component: (item) => (
                        <BreakText value={item.pickupAddress} fontSize={"14px"} />
                    ),
                },
                {
                    title: "Dropoff Location ",
                    extractor: "dropoffAddress",
                    align: "left",
                    isSortable: true,
                    component: (item) => (
                        <BreakText value={item.pickupAddress} fontSize={"14px"} />
                    ),
                },
                {
                    title: "Status",
                    extractor: "status",
                    component: (item) => (
                        <StatusBadge bookingStatus={true} value={item.status?.id} />
                    ),
                    isSortable: true,
                },
                { title: "Actions", extractor: "actions", align: "center" },
            ]}
            data={bookingQuery?.data?.data}
            loading={bookingQuery?.isLoading}
            totalResults={bookingQuery?.data?.meta?.totalCount}
            totalPages={bookingQuery?.data?.meta?.totalPages}
            pageNo={query?.PageNumber}
            pageSize={query?.PageSize}
            onQueryChange={onQueryChange}
            query={query}
            onRefresh={() => bookingQuery.refetch()}
            isRefreshing={bookingQuery?.isFetching}
            onView={onView}
            onDelete={onDelete}
        />
    )
}

export default UserBookings