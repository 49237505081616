import {
  chakra,
  Flex,
  Box,
  Text,
  useColorMode,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import * as Yup from "yup";
import React from "react";
import { getColor, colorKeys } from "../../../config/constants/appColors";

import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import FormInput from "../../../components/Forms/FormInput";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import IMAGES from "../../../config/constants/images";

import { addUser, resetTemp } from "../../../config/redux/slices/userSlice";
import { useResetPassword } from "../../../config/query/authQuery";

const Reset = () => {
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const { reset } = useSelector((state) => state.user);

  const resetPasswordQuery = useResetPassword();

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  function onSubmit(values) {
    resetPasswordQuery
      .mutateAsync({
        password: values?.newPassword,
        email: reset?.tempEmail,
        token: reset?.tempToken,
      })
      .then((response) => {
        dispatch(addUser(response));
        dispatch(resetTemp());
        navigate("/admin");
      })
      .catch((error) => console.error(error));
  }
  if (!reset?.tempEmail && !reset?.tempToken) {
    return <Navigate to="/auth/forgot-password" />;
  }

  return (
    <Flex
      height={"100vh"}
      justify="center"
      align="center"
      bg={getColor(colorKeys.white, colorMode)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full" w="full">
        <Box
          h="full"
          background={`linear-gradient(90deg, rgba(43,61,79,0.61) 0%, rgba(43,61,79,0.61) 100%), url(${IMAGES.AUTH_HERO})`}
          display={{ base: "none", md: "block" }}
        />

        <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
          <Box w="full" maxW="500px">
            <Box w="110px" m="auto">
              <Image w="full" h="full" src={tenant?.logoUrl} fallbackSrc={IMAGES.LOGO} alt="logo" />
            </Box>

            <Box>
              <Text
                fontSize="30px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={5}
              >
                Reset Password
              </Text>
              <Text
                fontSize="18px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={1}
              >
                Reset Your Password via Email
              </Text>
            </Box>
            <Flex
              mt={12}
              borderRadius={"5px"}
              justify={"center"}
              align={"center"}
              bg={getColor(colorKeys.purpleBlue, colorMode)}
              height={"55px"}
            >
              <Text
                fontSize="13px"
                textAlign="center"
                lineHeight={"19px"}
                fontWeight={"400"}
                color={getColor(colorKeys.white, colorMode)}
              >
                Reset Your Password
              </Text>
            </Flex>
            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">
              <FormInput
                id={"newPassword"}
                label={"New Password"}
                type="password"
                secure={true}
                placeholder="Enter New Password"
                required={true}
                errors={errors}
                control={control}
                inputProps={{ size: "lg", fontSize: "15px" }}
                containerStyles={{ mt: 5 }}
                hideLabel={true}
              />

              <FormInput
                id={"confirmPassword"}
                label={"Confirm Password"}
                type="password"
                secure={true}
                placeholder="Confirm Password"
                required={true}
                errors={errors}
                control={control}
                inputProps={{ size: "lg", fontSize: "15px" }}
                containerStyles={{ mt: 5 }}
                hideLabel={true}
              />

              <PrimaryButton
                isLoading={resetPasswordQuery.isLoading}
                mt={5}
                type="submit"
                p="18px 33px"
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                w="full"
                h="50px"
                rounded="md"
                fontWeight={"light"}
                color="white"
              >
                Reset
              </PrimaryButton>
            </chakra.form>
          </Box>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default Reset;
