import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDUW0W-iQU3x3vyFbJSD24k0qJ9w_sMgB0",
    authDomain: "corporate-7cb14.firebaseapp.com",
    projectId: "corporate-7cb14",
    storageBucket: "corporate-7cb14.appspot.com",
    messagingSenderId: "832203385252",
    appId: "1:832203385252:web:1e717751f51904030af931",
    measurementId: "G-RCJNX95854"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async (onRecieveCallback) => {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
        console.info('Notification permission granted.');
        const token = await getToken(messaging);
        localStorage.setItem('pushToken', token)

    } else {
        console.info('Unable to get permission to notify.');
        return null
    }

    // Handle foreground notifications
    onMessage(messaging, onRecieveCallback);
};

export { messaging, setupNotifications };