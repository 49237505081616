import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { preparePayload } from "../../../../config/helpers/formUtil/corporateUtil";
import FormModal from "../../../../components/Forms/FormModal";
import FormInput from "../../../../components/Forms/FormInput";
import { EMAIL_REGEX } from "../../../../config/constants/regexConstants";
import { SimpleGrid } from "@chakra-ui/react";
import FormPhoneInput from "../../../../components/Forms/FormPhoneInput";
import { useCreateBookingCustomer } from "../../../../config/query/bookingQuery";
import { DEFAULT_COUNTRY_CODE } from "../../../../config/constants/settings";

const AddNewUser = ({ disclosure, localNumber, onUserData }) => {
  const createCustomerQuery = useCreateBookingCustomer();

  useEffect(() => {
    // Use the dependency array to trigger the setValue call only when 'phone' prop changes
    setValue("localNumber", localNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localNumber]);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset: resetForm,
    watch,
    clearErrors,
    setError,
  } = useForm({
    values: {
      countryCode: DEFAULT_COUNTRY_CODE,
    },
  });

  const { countryCode, localNumber: localCustomerNumber } = watch();

  const onSubmit = (values) => {
    let formMutate = createCustomerQuery.mutateAsync;
    formMutate(preparePayload(values, values?.id))
      .then((res) => {
        disclosure.onClose();
        onUserData(res);
        resetForm();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <FormModal
      title={"Add Customer"}
      disclosure={disclosure}
      isSubmitting={createCustomerQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"50rem"}
      reset={resetForm}
      onCloseFn={() => {
        resetForm();
      }}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
        <FormInput
          id="firstName"
          label="First Name"
          control={control}
          errors={errors}
          required={true}
          placeholder="Enter first name"
          inputProps={{ size: "sm" }}
        />

        <FormInput
          id="lastName"
          label="Last Name"
          control={control}
          errors={errors}
          required={true}
          placeholder="Enter last name"
          inputProps={{ size: "sm" }}
        />

        <FormPhoneInput
          id={"localNumber"}
          label="Phone Number"
          type="tel"
          placeholder="Enter your phone number"
          required={true}
          errors={errors}
          control={control}
          setCountryCodeValue={(value) => {
            setValue("countryCode", value);
          }}
          countryCodeValue={countryCode}
          phoneNumberValue={localCustomerNumber}
          setPhoneNumberValue={(value) => {
            setValue("localNumber", value);
          }}
          setError={setError}
          clearErrors={clearErrors}
          inputProps={{ size: "sm" }}
          size="sm"
        />
        
        <FormInput
          id="email"
          label="Email"
          control={control}
          errors={errors}
          required={true}
          placeholder="Enter email"
          inputProps={{ size: "sm" }}
          type="email"
          rules={{
            pattern: {
              value: EMAIL_REGEX,
              message: "Please enter a valid email",
            },
          }}
        />
      </SimpleGrid>
    </FormModal>
  );
};

export default AddNewUser;
