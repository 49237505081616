import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect } from 'react'
import NavItem from "./NavItem";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

import WhiteLogo from "../../SVGComponents/WhiteLogo";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import IMAGES from "../../../config/constants/images";

const Sider = ({ disclosure, desktopDisclosure }) => {
  const tenant = useSelector((state) => state.tenant?.tenant)
  const { colorMode } = useColorMode();
  const { pathname } = useLocation();

  const menuList = [
    {
      icon: APP_ICONS.DASHBOARD,
      title: "Dashboard",
      link: "/admin/dashboard",
    },
    {
      icon: APP_ICONS.USERS,
      title: "Users",
      link: "/admin/users",
    },
    {
      icon: APP_ICONS.BOOKINGS,
      title: "Bookings",
      link: "/admin/bookings",
    },
    {
      icon: APP_ICONS.PROFILE,
      title: "Profile",
      link: "/admin/profile",
    },
    {
      icon: APP_ICONS.DOLLAR_SIGN,
      title: "Billing",
      link: "/admin/billing",
    },
  ];

  useEffect(() => {
    disclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const SidebarContent = (props) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg={getColor(colorKeys.siderBackground, colorMode)}

      transition=".3s ease"
      w={desktopDisclosure.isOpen ? "70px" : "255px"}
      {...props}
    >
      <Flex
        justify="center"
        h={"70px"}
        align="center"
        bg={getColor(colorKeys.siderBackground, colorMode)}
      >
        <Link to="/admin/dashboard">
          <Image
            h={"70px"}
            w="auto"
            objectFit={"contain"}
            src={tenant?.logoUrl}
            fallbackSrc={IMAGES.LOGO}
            _dark={{
              filter: "brightness(0) invert(1)"
            }}
          />
        </Link>
      </Flex>

      <Box borderTop={"1px solid #6c6c6c"}>
        <VStack
          spacing={1}
          mt={4}
          direction="column"
          as="nav"
          fontSize="sm"
          color="primaryText"
          aria-label="Main Navigation"
        >
          {menuList.map((item, index) => (
            <NavItem
              key={index}
              icon={item.icon}
              title={item?.title}
              link={item.link}
              subsider={item.subsider}
              isOpen={!desktopDisclosure.isOpen}
            />
          ))}
        </VStack>
      </Box>
    </Box>
  );
  return (
    <React.Fragment>
      <SidebarContent
        display={{
          base: "none",
          md: "unset",
        }}
      />

      <Drawer
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="left"
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default Sider;
