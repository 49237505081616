import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, Polygon, Polyline, Marker } from '@react-google-maps/api';
import { Box, useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';
import { getDefaultCoordinates } from '../../config/helpers/locationHelper';
import PickUpLocationIcon from "../../assets/icons/PickUpLocationIcon.svg";
import DropOffLocationIcon from "../../assets/icons/DropOffLocationIcon.svg";
import StopsLocationSvg from "../../assets/icons/StopsLocationSvg.svg";
import { DEFAULT_MAX_MAP_ZOOM, getGoogleMapOptions, getMapStyles, getPolylineOptions, getStylers } from './mapConfig';

const PolygonBox = ({ polylineCoords, setPolylineCoords, polylineLastCoords, disabled, center = getDefaultCoordinates(), containerProps, drawPolygon = false, polygonsArray, isPolyline, markers, currentScreen, polyLineDefaultColor, customZoom }, ref) => {

    const { colorMode } = useColorMode();
    const polylineRef = useRef(null);
    const listenersRef = useRef([]);
    const [clickedCoords, setClickedCoords] = useState(null);
    const [mapZoom, setMapZoom] = useState(8);
    const [map, setMap] = useState(null);
    const onLoadMap = useCallback((map) => setMap(map), []);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polylineRef.current) {
            const nextPath = polylineRef.current
                .getPath()
                .getArray()
                .map((latLng) => latLng.toJSON());
            setPolylineCoords(nextPath);
        }
    }, [setPolylineCoords]);

    // Bind refs to current Polyline and listeners
    const onLoad = useCallback(
        (polyline) => {
            polylineRef.current = polyline;
            const path = polyline.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove());
        polylineRef.current = null;
    }, []);

    const handleMapClick = (event) => {
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const newCoords = [...polylineCoords, { lat, lng }];
        setPolylineCoords(newCoords)
    };

    const getBoundsPrepared = (coordinatesFlatArrayList) => {
        const bounds = new window.google.maps.LatLngBounds();
        coordinatesFlatArrayList.forEach(item => {
            bounds.extend({
                lat: item.lat,
                lng: item.lng,
            });
        });
        return bounds;
    }

    /*
    * Ride specific only
    * */
    const getIconsForMarkers = (index, totalItem = 3) => {
        let iconName = StopsLocationSvg;

        if (index === 0) {
            iconName = PickUpLocationIcon;
        } else if (index === totalItem) {
            iconName = DropOffLocationIcon;
        }
        return iconName;
    }

    useEffect(() => {
        if (center.lat === clickedCoords?.lat && center.lng === clickedCoords?.lng) return;
        else setClickedCoords(center)
        if (!customZoom && map) {
            setMapZoom(12)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center, map])

    const updateZoomOnSingleView = () => {
        if (map) {
            let zoom = map.getZoom();
            if (zoom < 6) {
                setMapZoom(6);
            } else if (zoom > 6 && zoom < 10) {
                setMapZoom(8);
            } else if (zoom > 6 && zoom <= 13) {
                setMapZoom(11);
            } else if (zoom > 13 && zoom <= 15) {
                setMapZoom(12.5);
            }
            map.setZoom(mapZoom);
        }
    }

    useEffect(() => {
        if (map) {
            if (!isPolyline && polygonsArray && polygonsArray.length > 0) {
                const flatCoordinates = polygonsArray.flatMap((x) => x);
                const bounds = getBoundsPrepared(flatCoordinates);
                map.fitBounds(bounds);
            }
            else if (isPolyline && !polygonsArray && polylineCoords.length > 0) {
                let coordinatesList = [...polylineCoords, ...(polylineLastCoords ? [polylineLastCoords] : [])];
                let bounds = getBoundsPrepared(coordinatesList);
                setTimeout(() => {
                    map.fitBounds(bounds);
                }, 500);
            }
            else if (polylineCoords && polylineCoords.length > 0) {
                const bounds = getBoundsPrepared(polylineCoords);
                map.fitBounds(bounds);

                setTimeout(() => {
                    updateZoomOnSingleView();
                }, 500);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, polygonsArray, polylineCoords, polylineLastCoords]);

    const polylineOptions = getPolylineOptions('#FF0000', disabled)


    return (
        <Box w="full" h="calc(100vh - 250px)" ref={ref} {...containerProps}>
            <GoogleMap
                onLoad={onLoadMap}
                mapContainerStyle={getMapStyles(disabled)}
                zoom={mapZoom}
                defaultMaxZoom={DEFAULT_MAX_MAP_ZOOM}
                center={clickedCoords}
                onClick={handleMapClick}
                options={getGoogleMapOptions({ styles: getStylers(colorMode), disabled, maxZoom: Infinity, zoom: mapZoom })}
            >
                {isPolyline
                    ? <>
                        {markers && markers.map((marker, index) => {
                            //
                            return (
                                <Marker
                                    key={index}
                                    position={{ lat: marker?.location?.lat, lng: marker?.location?.lng }}
                                    icon={{
                                        url: getIconsForMarkers(index, markers.length - 1)
                                    }}
                                />
                            )
                        })}
                        <Polyline
                            path={polylineCoords}
                            draggable={false}
                            editable={false}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={polylineOptions}
                        />
                    </>
                    : <>
                        <Polygon
                            path={polylineCoords}
                            draggable={false}
                            editable={false}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={polylineOptions}
                        />

                        {polygonsArray && polygonsArray.map((polygon, index) => (
                            <Polygon
                                key={index}
                                path={polygon}
                                draggable={false}
                                editable={false}
                                onUnmount={onUnmount}
                                options={polylineOptions}
                            />
                        ))}

                    </>}


            </GoogleMap>
        </Box >
    );
}

export default React.forwardRef(PolygonBox);