import React from 'react'
import { Icon, IconButton, useColorMode, chakra, shouldForwardProp } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion';
import { colorKeys, getColor } from '../../../config/constants/appColors'
import APP_ICONS from '../../../config/constants/icons'
import COLOR_MODES from '../../../config/constants/colorModes'
import { useState } from 'react';
import { useEffect } from 'react';

const ChakraBox = chakra(motion.div, {
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const ColorModeSwitch = () => {
    const { colorMode, toggleColorMode } = useColorMode()

    const [animate, setAnimate] = useState(false)

    const toggle = () => {
        setAnimate(true)
    }

    useEffect(() => {
        if (animate) {
            setTimeout(() => {
                toggleColorMode()
            },1500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animate])
    return (
        <>
            <ChakraBox
                animate={animate ? {
                    scale: [1, 2, 1],
                    rotate: [0, 270, 0],
                    borderRadius: ["20%", "50%", "20%"],
                } : {}}
                // @ts-ignore no problem in operation, although type error appears.
                transition={{
                    duration: 3,
                    ease: "easeInOut",
                    repeat: 0,
                    repeatType: "loop",
                }}
                onAnimationComplete={() => {
                    setAnimate(false)
                }}
            >
                <IconButton
                    size="sm"
                    rounded="full"
                    bg="transparent !important"
                    color={getColor(colorKeys.primaryText, colorMode)}
                    onClick={toggle}
                    icon={colorMode === COLOR_MODES.LIGHT
                        ? <Icon boxSize="6" as={APP_ICONS.DarkMode} />
                        : <Icon boxSize="6" as={APP_ICONS.LighMode} />
                    }
                />
            </ChakraBox>
        </>
    )
}

export default ColorModeSwitch