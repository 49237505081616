import { Box, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const NotificationItem = ({ item }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      px="2"
      py="1"
      cursor={"pointer"}
      _hover={{ bg: getColor(colorKeys.tableRowHoverBackground, colorMode) }}
    >
      <Text fontSize={"13px"} fontWeight={"bold"}>
        {item?.title}
      </Text>
      <Text
        colorScheme="blue"
        _hover={{ textDecor: "underline" }}
        as={"a"}
        target="_blank"
        href={item?.url}
        fontSize={"12px"}
      >
        {item?.url}
      </Text>
      <Text fontSize={"12px"}>{item?.description}</Text>
    </Box>
  );
};

export default NotificationItem;
