import { Box, Flex, Heading, Icon, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const TripBox = ({ trip }) => {
    const { colorMode } = useColorMode()

    return (
        <Box>
            {trip?.map((item, index, arr) => {
                const icon = index === 0 ? APP_ICONS.PICKUP : index === arr.length - 1 ? APP_ICONS.DROP_OFF : APP_ICONS.STOP
                const color = index === 0 ? colorKeys.primaryButtonFill : index === arr.length - 1 ? colorKeys.primaryButtonFill : colorKeys.gray
                return (
                    <Box key={index} p="1px 10px">
                        <Flex>
                            <Icon as={icon} color={getColor(color, colorMode)} mr={2} />
                            <Box>
                                <Heading color={getColor(color, colorMode)} fontWeight={"500"} fontSize={"13px"}>{item.label}</Heading>
                                <Text
                                    display={"block"}
                                    whiteSpace={"break-spaces"}
                                    width={"100%%"}
                                    {...(index !== arr.length - 1 && {
                                        borderLeft: `1px dashed ${getColor(colorKeys.gray, colorMode)}`,
                                        pos: "relative",
                                        left: "-17px",
                                        pl: "17px",
                                    })}
                                    mt={2}
                                    fontSize="13px"
                                    pb={"5px"}
                                >{item.value}</Text>
                            </Box>
                        </Flex>
                    </Box>
                )
            })}
        </Box>
    )
}

export default TripBox