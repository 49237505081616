import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Post } from "../api";
import API_CONSTANTS from "../constants/api";

export const useLogin = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.login,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};
export const useForgotPassword = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.forgetPassword,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};
export const useVerifyOtp = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.verifyOtp,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};
export const useResetPassword = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.resetPassword,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useSetPassword = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.setPassword,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};


export const useVerifySetPassword = () => {
  const token = useSelector((state) => state.user?.token)
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.AUTH.verifySetPassword,
        token,
        body,
        toastError: true,
        toastMessage: true,
      })
      return data
    },
  })
}

export const useGetTenantDetails = () => {
  return useMutation({
    mutationFn:async () => {
      const data = await Get({
        path:API_CONSTANTS.AUTH.getTenantDetails,
        toastError:true,
      })
      return data
    }
  })
}