import React, { useEffect } from "react";
import AppRouter from "./config/routes";
import { setupNotifications } from "./config/firebase/firebaseConfig";
import {
  sendNativeNotification,
  toastNotification,
} from "./config/helpers/notificationHelper";
import useVisibilityChange from "./config/hooks/useVisibilityChange";
import { useQueryClient } from "@tanstack/react-query";

const App = () => {
  const queryClient = useQueryClient();
  const isForeground = useVisibilityChange();

  useEffect(() => {
    setupNotifications((payload) => {
      console.info("New Push Notification: ", payload);
      queryClient.refetchQueries(["notifications"]);
      if (!isForeground) {
        sendNativeNotification({
          title: payload.notification?.title,
          body: payload.notification?.body,
          image: payload.notification?.image,
        });
      } else {
        toastNotification({
          title: payload.notification?.title,
          description: payload.notification?.body,
          status: "info",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppRouter />;
};

export default App;
