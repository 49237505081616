import { RIDE_STATUSES, USER_STATUSES } from "./enums";

const getColor = (color, colorMode) => {
  if (!colorMode) colorMode = "light";
  return colors[colorMode][color];
};

const colorKeys = {
  primary: "primary",
  primaryHover: "primaryHover",
  secondary: "secondary",
  yellowColor: "yellowColor",
  primaryText: "primaryText",
  secondaryText: "secondaryText",
  highlightedText: "highlightedText",
  tableStripedRowBackground: "tableStripedRowBackground",
  dimBlue: "dimBlue",
  dimmerBlue: "dimmerBlue",
  lightBlue: "lightBlue",
  lighterBlue: "lighterBlue",
  spanishYellow: "spanishYellow",
  purpleBlue: "purpleBlue",
  lightBackgroundFill: "lightBackgroundFill",
  lighterBackgroundFill: "lighterBackgroundFill",
  lightGrayBackgroundFill: "lightGrayBackgroundFill",
  activeNavButton: "activeNavButton",
  primaryButtonFill: "primaryButtonFill",
  primaryButtonFillHover: "primaryButtonFillHover",

  headingText: "headingText",
  layoutHeaderBackground: "layoutHeaderBackground",
  siderBackground: "siderBackground",
  layoutBoxBackground: "layoutBoxBackground",
  subNavItemActive: "subNavItemActive",
  subNavItemActiveText: "subNavItemActiveText",
  green: "greenColor",
  tableBackground: "tableBackground",
  tableFilterBoxBackground: "tableFilterBoxBackground",
  tableFilterInputBackground: "tableFilterInputBackground",
  tableFilterBackground: "tableFilterBackground",
  tableFilterDropdownBackground: "tableFilterDropdownBackground",
  tableFilterBorder: "tableFilterBorder",
  tableRowHoverBackground: "tableRowHoverBackground",

  paginationNavigationColor: "paginationNavigationColor",
  paginationNavigationBgColor: "paginationNavigationBgColor",
  paginationNavigationHoverColor: "paginationNavigationHoverColor",
  paginationNumberButton: "paginationNumberButton",
  black: "black",
  white: "white",
  dark: "dark",
  smoke: "smoke",
  whiteSmoke: "whiteSmoke",
  gray: "gray",
  lightGray: "lightGray",
  danger: "danger",
  success: "success",
  warning: "warning",
  alwaysWhite: "alwaysWhite",
  avatarBg: "avatarBg",
  red: "red",

  //STOPS COLORS
  pickup: "pickup",
  dropoff: "dropoff",
  stop: "stop",
};

const colors = {
  dark: {
    primary: "#04be76",
    primaryHover: "#40844eb8",
    secondary: "#e9e3ff",

    primaryText: "#FBFBFB",
    secondaryText: "#74788D",
    highlightedText: "#fbfbfb",

    dimBlue: "#3F78B9",
    dimmerBlue: "#2B3D4F",
    lightBlue: "#47A7DD",
    lighterBlue: "#8CC8EA4D",
    spanishYellow: "#F2B917",

    lightBackgroundFill: "#2e2e2e",
    lighterBackgroundFill: "#424242",
    lightGrayBackgroundFill: "#444c5b",
    activeNavButton: "#363636",
    tableStripedRowBackground: "#51515238",
    primaryButtonFill: "#47A7DD",
    primaryButtonFillHover: "#8CC8EA4D",

    headingText: "#383839",
    layoutHeaderBackground: "#2a2a2a",
    siderBackground: "#2a2a2a",
    layoutBoxBackground: "#424242",
    subNavItemActive: "#d8e1de",
    subNavItemActiveText: "#393939",

    tableBackground: "#2e2e2e",
    tableFilterBoxBackground: "#f0f0f0",
    tableFilterInputBackground: "#f0f0f0",
    tableFilterBackground: "#f0f0f0",
    tableFilterDropdownBackground: "#e1e1e1",
    tableFilterBorder: "#606060",
    tableRowHoverBackground: "#424242",
    purpleBlue: "#2B3D4F",
    paginationNavigationColor: "#fff",
    paginationNavigationBgColor: "#515151",
    paginationNavigationHoverColor: "#2e2e2e",
    paginationNumberButton: "#fff",

    white: "#2d3748",
    dark: "#2a2a2a",
    smoke: "#AAAAAA",
    whiteSmoke: "#1d1d1d",
    gray: "#949494",
    danger: "#ae0000",
    success: "green",
    warning: "yellow",
    alwaysWhite: "#fff",
    red: "#FD3F3F",
    lightGray: "#A5A5A5",
    black: "#000000",
    avatarBg: "#084089",
    //STOPS COLORS
    pickup: "#00AF27",
    dropoff: "#FF0000",
    stop: "#47A7DD",
  },
  light: {
    primary: "#04be76",
    primaryHover: "#40844eb8",
    secondary: "#e9e3ff",

    primaryText: "#333",
    secondaryText: "#575153",
    highlightedText: "#9e4f8f",

    dimBlue: "#3F78B9",
    dimmerBlue: "#2B3D4F",
    lightBlue: "#47A7DD",
    lighterBlue: "#8CC8EA4D",
    spanishYellow: "#F2B917",

    lightBackgroundFill: "#eaf5f2",
    lighterBackgroundFill: "#d5e1de",
    lightGrayBackgroundFill: "#e1e1e1",
    activeNavButton: "#47A7DD",
    tableStripedRowBackground: "#eaeaea",

    primaryButtonFill: "#47A7DD",
    primaryButtonFillHover: "#47a7ddb8",

    headingText: "#383839",
    layoutHeaderBackground: "#fff",
    siderBackground: "#2B3D4F",
    layoutBoxBackground: "#F6F6F6",
    subNavItemActive: "#d8e1de",
    subNavItemActiveText: "#575153",
    greenColor: "#34C38F",
    tableBackground: "#fff",
    tableFilterBoxBackground: "#f0f0f0",
    tableFilterInputBackground: "#f0f0f0",
    tableFilterBackground: "#f0f0f0",
    tableFilterDropdownBackground: "#e1e1e1",
    tableFilterBorder: "#e2e8f0",
    tableRowHoverBackground: "#ebf5f2",

    paginationNavigationColor: "#000",
    paginationNavigationBgColor: "#edf2f7",
    paginationNavigationHoverBgColor: "#fbfbfb",
    paginationNumberButton: "#9e4f8f",
    purpleBlue: "#2B3D4F",
    white: "#fbfbfb",
    dark: "#2a2a2a",
    smoke: "#AAAAAA",
    whiteSmoke: "#f5f5f5",
    gray: "#949494",
    lightGray: "#eaeaea",
    danger: "#ae0000",
    success: "green",
    warning: "yellow",
    alwaysWhite: "#fff",
    red: "#FD3F3F",
    black: "#000000",
    avatarBg: "#084089",

    //STOPS COLORS
    pickup: "#00AF27",
    dropoff: "#FF0000",
    stop: "#47A7DD",
  },
};

const rideStatColors = {
  CANCELLED: "#ff4c4c",
  DISPATCHING: "#C998ED",
  ACCEPTED: "#B6D455",
  AVAILABLE: "#B6D455",
  ON_THE_WAY: "#F4C622",
  ARRIVED_AND_WAITING: "#CE7B00",
  POB: "#27948C",
  DROPPED_OFF: "#EB3620",
  COMPLETED: "#2b3d4f",
  NOSHOWS: "pink",
  INCIDENTS: "yellow",
  UNAVAILABLE: "#96979C",
  PARKING_LOT: "#DF269D",
};

const userStatColors = {
  BLOCKED: "red",
  ACTIVE: "green",
  PENDING: "gray",
};
const getUserStatColor = (status) => {
  switch (status) {
    case USER_STATUSES.Blocked:
      return userStatColors.BLOCKED;
    case USER_STATUSES.Active:
      return userStatColors.ACTIVE;
    case USER_STATUSES.Pending:
      return userStatColors.PENDING;
    default:
      return userStatColors.UNAVAILABLE;
  }
};
const getRideStatColor = (status) => {
  switch (status) {
    case RIDE_STATUSES.Cancelled:
      return rideStatColors.CANCELLED;
    case RIDE_STATUSES.WaitingForAccept:
      return rideStatColors.DISPATCHING;
    case RIDE_STATUSES.Accepted:
      return rideStatColors.ACCEPTED;
    case RIDE_STATUSES.Available:
      return rideStatColors.AVAILABLE;
    case RIDE_STATUSES.DriverOnTheWay:
      return rideStatColors.ON_THE_WAY;
    case RIDE_STATUSES.Arrived:
      return rideStatColors.ARRIVED_AND_WAITING;
    case RIDE_STATUSES.InTransit:
      return rideStatColors.POB;
    case RIDE_STATUSES.Reached:
      return rideStatColors.DROPPED_OFF;
    case RIDE_STATUSES.Completed:
      return rideStatColors.COMPLETED;
    case RIDE_STATUSES.NoShows:
      return rideStatColors.NOSHOWS;
    case RIDE_STATUSES.Incidents:
      return rideStatColors.INCIDENTS;
    case RIDE_STATUSES.ParkingLot:
      return rideStatColors.PARKING_LOT;
    default:
      return rideStatColors.UNAVAILABLE;
  }
};

export {
  getColor,
  colors,
  colorKeys,
  rideStatColors,
  getRideStatColor,
  getUserStatColor,
};
