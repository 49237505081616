import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Delete, Post,Patch } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";

export const useBookings = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["bookings", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.list}?${appendQueryParams(params)}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useDeleteBooking = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.BOOKINGS.bookingBase}/${id}`,
        token,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useBooking = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["booking", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.bookingBase}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useCorporateCustomer = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["corporateCustomer", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.viewCorporateCustomer}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useCreateBooking = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      body = prepareData(body, API_CONSTANTS.BOOKINGS.dataKeys);
      const { data } = await Post({
        path: API_CONSTANTS.BOOKINGS.bookingBase,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useSearchUserByPhone = (phone) => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationKey: ["phoneNumber"],
    mutationFn: async () => {
      const response = await Get({
        path: API_CONSTANTS.BOOKINGS.searchUserByPhone.replace(
          ":phoneNumber",
          phone
        ),
        token,

        toastError: true,
        toastMessage: false,
      });
      return response;
    },
  });
};

export const useCreateCustomer = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      body = prepareData(body, API_CONSTANTS.CORPORATE.dataKeys);
      const { data } = await Post({
        path: API_CONSTANTS.CORPORATE.create,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};

// export const useSearchUserByPhone = (phone) => {
//   const token = useSelector((state) => state.user.token);
//   return useMutation({
//     mutationKey: ["phoneNumber"],
//     mutationFn: async () => {
//       const response = await Get({
//         path: API_CONSTANTS.BOOKINGS.searchUserByPhone.replace(
//           ":phoneNumber",
//           phone
//         ),
//         token,

//         toastError: true,
//         toastMessage: false,
//       });
//       return response;
//     },
//   });
// };

export const useBookingTrackLink = (id) => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationKey: ["corporateCustomer", id],
    mutationFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.bookingTrackingLink}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useVehicleTypeOptions = () => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["types-options"],
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.BOOKINGS.typesLookup,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
  });
};

export const useSearchVehicleType = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationKey: ["search-vechical-type"],
    mutationFn: async (body) => {
      const response = await Post({
        path: `${API_CONSTANTS.BOOKINGS.searchVehicleType}`,
        token,
        body,
        toastError: true,
        toastMessage: false,
      });
      return response;
    },
  });
};

export const useApplyPromoCode = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Post({
        path: API_CONSTANTS.BOOKINGS.applyPromoCode,
        token,
        body: body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
  });
};

export const useGetCustomerById = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationKey: ["id"],
    mutationFn: async (id) => {
      const response = await Get({
        path: API_CONSTANTS.BOOKINGS.getCustomerById.replace(":id", id),
        token,

        toastError: true,
        toastMessage: false,
      });
      return response;
    },
  });
};

export const useCreateBookingCustomer = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Post({
        path: API_CONSTANTS.BOOKINGS.createBookingCustomer,
        token,
        body: body,
        toastError: true,
        toastMessage: true
      })
      return res
    },
  })
}


export const useCostBreakdown = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["costBreakdown", id],
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.BOOKINGS.costBreakdown.replace(":id", id),
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
}

export const useCancelBooking = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ id, body }) => {
      const { data } = await Patch({
        path: API_CONSTANTS.BOOKINGS.cancelBooking.replace(":id", id),
        token,
        body,
        toastMessage: true,
      });
      return data;
    },
  });
}

export const useCustomerBookings = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["customerBookings", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.corporateBookings}?${appendQueryParams(params)}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
    keepPreviousData: true,
  });
}