import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useGetTenantDetails } from '../../../config/query/authQuery'
import { updateTenant } from '../../../config/redux/slices/tenantSlice'
import { changeFavicon } from '../../../config/helpers/brandingHelper'

const AuthLayout = () => {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user?.token)
    const tenantDetails = useGetTenantDetails()

    useEffect(() => {
        tenantDetails
            .mutateAsync()
            .then((res) => {
                dispatch(updateTenant(res))
                localStorage.setItem("tenant", res.id)
                localStorage.setItem("favIconUrl", res.favIconUrl)
                changeFavicon(res.favIconUrl)
            }).catch((err) => console.warn(err))
    }, [])

    if (token) {
        return <Navigate to="/admin" replace={true} />
    }

    return <Outlet />
}

export default AuthLayout