import React, { useState } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import BookingInfo from "./BookingInfo";
import TipPromo from "./TipPromo";
import { RideType } from "../../../../config/constants/enums";
import { useCreateBooking } from "../../../../config/query/bookingQuery";
import LocationInfo from "./LocationInfo";
import PassengerInfo from "./PassengerInfo";
import { useNavigate } from "react-router";
import { formatDateTimeWithoutConvertingToUtc } from "../../../../config/helpers/dateHelper";

const BookingForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [formKey, setFormKey] = useState(0);
  const createBookingQuery = useCreateBooking();

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    formState: { errors },
    watch,
  } = useForm({
    values: {
      stops: [],
      rideType: 0,
      customerType: 0,
      corporateId: 0,
    },
  });

  const onSubmit = (values) => {
    const stopsValues = values.stops.map((item) => ({
      name: item.label,
      address: item.label,
      location: item.latLng,
    }));
    values = {
      ...values,
      rideType: values.rideType ? RideType.SCHEDULED : RideType.NOW,
      rideTime: values.rideType
        ? formatDateTimeWithoutConvertingToUtc(values.rideTime)
        : formatDateTimeWithoutConvertingToUtc(new Date().toString()),
      bookingStops: [
        {
          name: values.pickup.label,
          address: values.pickup.label,
          location: values.pickup.latLng,
        },
        ...stopsValues,
        {
          name: values.destination.label,
          address: values.destination.label,
          location: values.destination.latLng,
        },
      ],
    };
    delete values.pickup;
    delete values.destination;
    delete values.stops;
    delete values.promoCode
    delete values.promoCodeData

    if (values.customerType) {
      delete values.corporateId;
    }

    createBookingQuery
      .mutateAsync(values)
      .then(() => {
        resetForm();
        queryClient.invalidateQueries("bookings");
        navigate(`/admin/bookings`);
      })
      .catch((error) => console.error(`Error while creating booking: ${error}`));
  };
  const resetFormValues = () => {
    resetForm({
      stops: [],
      rideType: 0,
      customerType: 0,
      corporateId: 0,
    });
    setFormKey((prevKey) => prevKey + 1);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} key={formKey}>
      <SimpleGrid
        spacing={5}
        columns={{ base: 1, md: 2, lg: 4 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <BookingInfo
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <PassengerInfo
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <TipPromo
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <LocationInfo
          control={control}
          errors={errors}
          watch={watch}
          isSubmitting={createBookingQuery.isLoading}
          onClearForm={resetFormValues}
        />
      </SimpleGrid>
    </Box>
  );
};

export default BookingForm;
