import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  SimpleGrid,
  chakra,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import FormInput from "../../../components/Forms/FormInput";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useChangePassword } from "../../../config/query/usersQuery";

const ChangePassword = () => {
  const { colorMode } = useColorMode();
  const changePassword = useChangePassword();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  function onSubmit(values) {
    changePassword
      .mutateAsync(values)
      .catch((error) => console.error(error))
  }

  return (
    <Box>
      <Heading fontSize={26}>Change Password</Heading>
      <Card mt={5}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)} p="25px">
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
              <FormInput
                label={"Old Password"}
                control={control}
                errors={errors}
                id="password"
                required={true}
                secure={true}
                placeholder="Enter your old password"
              />

              <FormInput
                label={"New Password"}
                control={control}
                errors={errors}
                id="newPassword"
                required={true}
                placeholder="Create new password"
                secure={true}
              />
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              spacing={5}
              mt={5}
              display={"flex"}
              justifyContent={"end"}
            >
              <Box w="49%">
                <FormInput
                  label={"Confirm Password"}
                  control={control}
                  errors={errors}
                  id="confirmNewPassword"
                  required={true}
                  placeholder="Confirm your password"
                  secure={true}
                />
              </Box>
            </SimpleGrid>
            <Flex placeContent={"end"}>
              <PrimaryButton
                isLoading={changePassword.isLoading}
                mt={10}
                type="submit"
                p="18px 33px"
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                w="300px"
                h="50px"
                rounded="md"
                fontWeight={"bold"}
                color="white"
              >
                Update
              </PrimaryButton>
            </Flex>
          </chakra.form>
        </CardBody>
      </Card>
    </Box>
  );
};

export default ChangePassword;
