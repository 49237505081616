import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Delete, Post, Patch } from "../api";
import API_CONSTANTS from "../constants/api";

export const useCardList = () => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["setup-intent"],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.PROFILE.cardList}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
};

export const useSetupIntent = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.PROFILE.setupIntent}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
  });
};

export const useDeleteCardLoggedInCustomer = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.PROFILE.deleteList}/${id}`,
        token,
        toastMessage: true,
      });
      return data;
    },
  });
};



export const usePrimaryCard = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const data = await Post({
        path: `${API_CONSTANTS.PROFILE.setPrimaryCard}/${id}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
  });
};

export const useUpdatePaymentType = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Patch({
        path: `${API_CONSTANTS.PROFILE.updatePaymentType}`,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
}

export const usePaymentType = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.PROFILE.updatePaymentType}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
}