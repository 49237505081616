import { useInfiniteQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";

export const useNotifications = (params) => {
    const token = useSelector((state) => state.user.token);
    return useInfiniteQuery({
        queryKey: ["notifications", params],
        queryFn: async ({ pageParam = 1 }) => {
            const data = await Get({
                path: `${API_CONSTANTS.NOTIFICATIONS.list}?${appendQueryParams({
                    ...params,
                    PageNumber: Number(pageParam),
                })}`,
                token,
                toastError: false,
                toastMessage: false,
            });
            return data;
        },
        refetchOnWindowFocus: true,
        getNextPageParam: (lastPage) => {
            return lastPage?.meta?.hasNextPage;
        },
        keepPreviousData: true,
    });
};