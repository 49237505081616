import React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Box } from "@chakra-ui/react";

const StripeConnect = ({ errorMessage }) => {
  return (
    <Box>
      <PaymentElement className="stripe-connect-form" />

      {errorMessage && <div>{errorMessage}</div>}
    </Box>
  );
};

export default StripeConnect;
