import React from "react";
import { Line } from "@ant-design/plots";
import { Flex, Heading, Text, useColorMode } from "@chakra-ui/react";
import { colorKeys, getColor } from "../../config/constants/appColors";

const MultiLineChart = ({ data = [] }) => {
  const { colorMode } = useColorMode()
  const COLOR_LINE = "#47A7DD";
  const config = {
    data,
    xField: "time",
    yField: "expense",
    seriesField: "category",
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    lineStyle: {
      stroke: COLOR_LINE,
    },
  };

  if (!data || data?.length === 0) return (
    <Flex flexDir={"column"} justify="center" align="center" minH="full" maxW="350px" m="auto">
      <Heading fontSize={20} color={getColor(colorKeys.dark, colorMode)}>No Data</Heading>
      <Text fontSize={"14px"} textAlign={"center"} color={getColor(colorKeys.gray, colorMode)}>Try changing dates or view mode to get data.</Text>
    </Flex>
  )

  return <Line {...config} />;
};

export default MultiLineChart;
