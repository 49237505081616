import React from "react";
import {
  Box,
  Card,
  CardBody,
  Heading,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import MultiLineChart from "../../../components/Charts/MultiLineChart";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useSpendingGraph } from "../../../config/query/dashboardQuery";
import { formatTimeByViewMode } from "../../../config/helpers/dateHelper";

const EarningChart = ({ query }) => {
  const { colorMode } = useColorMode();
  const spendingGraph = useSpendingGraph(query)
  return (
    <Card bg={getColor(colorKeys.tableBackground, colorMode)}>
      <CardBody>
        <Flex justifyContent={"space-between"}>
          <Heading as="h2" size={"md"} fontSize="22px" fontWeight={"600"}>
            Total Expense
          </Heading>


        </Flex>

        <Box mt={5} h="calc(100vh - 470px)">
          <MultiLineChart
            data={spendingGraph.data?.data?.map((item) => ({
              time: formatTimeByViewMode({ viewMode: query?.ViewMode, time: item.time }),
              expense: item.expense
            }))}
          />
        </Box>
      </CardBody>
    </Card >
  );
};

export default EarningChart;
