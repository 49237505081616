import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Icon,
  VStack,
  Flex,
  Heading,
  HStack,
  IconButton,
  Divider,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import APP_ICONS from "../../../../config/constants/icons";
import LabelValueRow from "../../../../components/BasicUI/DataBoxes/LabelValueRow";
import { breakWithCaps, formatAmount } from "../../../../config/helpers/stringHelper";
import { useCostBreakdown } from "../../../../config/query/bookingQuery";
import { colorKeys, getColor } from '../../../../config/constants/appColors';
import { EXTERNAL_WALLET_TYPE, PAYMENT_TYPE, PAYMENT_TYPE_LABELS } from '../../../../config/constants/enums';

const CostBreakdown = ({ disclosure }) => {
  const { colorMode } = useColorMode();
  const { bookingId } = useParams();
  const { isFetching, data, refetch } = useCostBreakdown(bookingId);

  useEffect(() => {
    if (disclosure?.isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosure?.isOpen]);

  const corporateRateAmount = data?.corporateRateAmount

  return (
    <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} isLazy>
      <ModalOverlay />
      <ModalContent>
        <Flex justify={"space-between"} p="10px 20px">
          <Heading fontSize="20px" p="2">
            Cost Breakdown
          </Heading>
          <HStack>
            <Button
              isLoading={isFetching}
              size="sm"
              onClick={refetch}
              leftIcon={<Icon as={APP_ICONS.REFRESH} />}
            >
              Refresh
            </Button>
            <IconButton
              size="sm"
              onClick={disclosure?.onClose}
              icon={<Icon as={APP_ICONS.CLOSE} />}
            />
          </HStack>
        </Flex>
        <ModalBody>
          <VStack spacing={0} align="stretch">
            <LabelValueRow label={"Fare"} value={`${formatAmount(data?.fare)}`} />
            <LabelValueRow label="Tech Fee" value={`${formatAmount(data?.techFee)}`} />
            <LabelValueRow label="Taxes" value={`${formatAmount(data?.tax)}`} />
            <LabelValueRow label="Toll" value={`${formatAmount(data?.tollFee)}`} />
            <LabelValueRow label="Additional Fee" value={`${formatAmount(data?.additionalFee)}`} />
            <LabelValueRow label="Promo Code" value={data?.promoCode} />
            <LabelValueRow label="Discount" valueProps={{ color: "red" }} value={`(${formatAmount(data?.discount)})`} />
            <LabelValueRow label="Payment Method" value={data?.paymentType?.name} />
            <LabelValueRow label="Payment Method" value={
              breakWithCaps(data?.externalWalletType?.id !== undefined
                ? EXTERNAL_WALLET_TYPE[data?.externalWalletType?.id]
                : PAYMENT_TYPE[data?.paymentMethod?.id])} />
            <LabelValueRow
              label={corporateRateAmount < 0
                ? "Corporate Discount"
                : "Account Fee"
              }
              valueProps={corporateRateAmount < 0 && { color: "red" }}
              value={
                corporateRateAmount < 0 ? `(${formatAmount(corporateRateAmount)})` : `${formatAmount(corporateRateAmount)}`
              }
            />
          </VStack>

          <Divider h="1px" mt={2} />

          <Box bg={getColor(colorKeys.highlightBg, colorMode)} mt={2}>
            {data?.paymentType?.id === PAYMENT_TYPE_LABELS.Cash && <LabelValueRow containerProps={{ _odd: {} }} label="Amount Collected" value={formatAmount(data?.amountCollected)} />}
            <LabelValueRow containerProps={{ _odd: {} }} label="Total" value={formatAmount(data?.totalFare)} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" mr={3} onClick={disclosure?.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CostBreakdown;
