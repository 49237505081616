import { formatPhoneNumber } from "../stringHelper"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    ...(formValues?.id && { id: formValues?.id, ...serverValues }),
    cityId: serverValues?.city?.id,
    languageId: serverValues?.language?.id,
})

export const preparePayload = (values, id) => ({
    ...values,
    ...(id && { id }),
    localNumber: formatPhoneNumber(values?.countryCode, values?.localNumber)
})