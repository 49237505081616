import { Flex, Heading, Skeleton, chakra } from '@chakra-ui/react'
import React from 'react'

const LabelValuePair = ({ label, value, colon = true, restricted = true, valueComponent, headingProps, flexDir = "row", spacing = 2, containerProps, valueProps, labelComponent, isLoading, loadingComponent }) => {
    if (restricted && !value && value !== 0) return null
    return (
        <Flex flexDir={flexDir} {...containerProps}>

            {labelComponent
                ? labelComponent
                : <Heading mr={spacing} size="sm" {...headingProps}>
                    {label}{colon && ':'}
                </Heading>}
            {(isLoading && loadingComponent) && loadingComponent}
            {isLoading && !loadingComponent && <Flex justify={"center"}><Skeleton width="20px" height="20px" mr={spacing} /></Flex>}
            {(!isLoading && valueComponent) && valueComponent}
            {!isLoading && !valueComponent &&
                <chakra.p whiteSpace="normal" fontSize="14px" {...valueProps}>{value}</chakra.p>
            }
        </Flex>
    )
}

export default LabelValuePair