import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  VStack,
  useColorMode,
  HStack,
  Button,
} from "@chakra-ui/react";
import { getPromoCodeStatus } from "../../../../config/helpers/formUtil/bookingUtil";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import { PAYMENT_TYPE } from "../../../../config/constants/enums";
import FormTextarea from "../../../../components/Forms/FormTextarea";
import { useCardList } from "../../../../config/query/profileQuery";
import FormInput from "../../../../components/Forms/FormInput";
import { useApplyPromoCode } from "../../../../config/query/bookingQuery";

const TipPromo = ({ control, errors, watch, setValue }) => {
  const { colorMode } = useColorMode();
  const customerCardsQuery = useCardList();
  const applyPromoCodeQuery = useApplyPromoCode();
  const { pickup, destination, userId, paymentMethod, promoCodeId, promoCode, vehicleTypeId, promoCodeData } = watch();

  const promoCodeStatus = useMemo(() => {
    return getPromoCodeStatus({ promoCode, promoCodeData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeId, promoCode]);

  function applyPromoCode() {
    const payload = {
      customerId: userId,
      pickup: pickup?.latLng,
      dropOff: destination?.latLng,
      promoCode: promoCode,
      vehicleTypeId: vehicleTypeId,
      paymentMethod: paymentMethod,
    };
    if (userId !== "undefined" && userId !== null) {
      applyPromoCodeQuery
        .mutateAsync(payload)
        .then((res) => {
          if (res) {
            setValue("promoCodeId", res?.promoCodeId)
            setValue("promoCodeData", { name: promoCode, id: res?.promoCodeId })
          }
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    setValue("creditCardId", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <Card>

      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Tip Promo</Heading>
      </CardHeader>

      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <VStack spacing={5} align="stretch">

          <FormSearchSelect
            id="paymentMethod"
            options={makeSelectList(PAYMENT_TYPE)}
            label={"Payment Method"}
            control={control}
            errors={errors}
            required={true}
            placeholder="Select payment method"
            inputProps={{ size: "sm" }}
          />

          {typeof paymentMethod !== "undefined" && Number(paymentMethod) === 1 && (
            <FormSearchSelect
              id="creditCardId"
              label={"Cards"}
              control={control}
              options={
                customerCardsQuery?.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.brand + " " + item.lastFourDigits,
                })) || []
              }
              errors={errors}
              required={true}
              placeholder="Select Cards"
              inputProps={{ size: "sm" }}
              isLoading={customerCardsQuery.isLoading}
            />
          )}

          <FormInput
            label={"Add Promo Code"}
            control={control}
            errors={errors}
            id="promoCode"
            placeholder="Enter promo code"
            inputProps={{ size: "sm" }}
          />

          {promoCode?.length > 0 && (
            <HStack justify="flex-end">
              <Button
                onClick={applyPromoCode}
                size={"sm"}
                marginTop={"-13px"}
                fontSize={"13px"}
                colorScheme={promoCodeStatus ? "gray" : "green"}
                variant={promoCodeStatus ? "solid" : "ghost"}
                pointerEvents={promoCodeStatus ? undefined : "none"}
              >
                {promoCodeStatus ? 'Apply' : 'Applied'}
              </Button>
            </HStack>
          )}

          {/* <FormInput
            label={"Add Tip"}
            control={control}
            errors={errors}
            id="tip"
            type={"number"}
            placeholder="Enter tip"
            inputProps={{ size: "sm" }}
          /> */}

          <FormTextarea
            id="dispatcherNote"
            label={"Dispatcher Notes"}
            control={control}
            errors={errors}
            placeholder="Enter dispatcher notes"
            inputProps={{ size: "sm" }}
          />

          <FormTextarea
            id="internalNote"
            label={"Internal Notes"}
            control={control}
            errors={errors}
            placeholder="Enter internal notes"
            inputProps={{ size: "sm" }}
          />
        </VStack >
      </CardBody >
    </Card >
  );
};

export default TipPromo;
