import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();

export const toastNotification = ({ title, description, status }) => {
    toast({
        title,
        description,
        status,
        duration: 5000,
        isClosable: true,
        position: "top-right",
        variant:"subtle"
    });
}

export const sendNativeNotification = ({ title, body, image }) => {
    const notification = new Notification(title, { body, icon:image });
    return notification;
}