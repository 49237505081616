import React from "react";
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  PopoverArrow,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const CancelBookingPopover = ({
  label = "Custom Popover",
  heading = "Heading",
  children,
  triggerProps,
  placement = "bottom",
  disclosure,
  isSubmitting,
  onSubmit,
}) => {
  const { colorMode } = useColorMode();
  const { isOpen, onToggle, onClose } = disclosure;

  return (
    <Box className="custom-popover">
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement={placement}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button onClick={onToggle} {...triggerProps}>
            {label}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">{heading}</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{children}</PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                colorScheme="blue"
                mr={3}
                size="sm"
                type="submit"
                isLoading={isSubmitting}
                onClick={onSubmit}
              >
                Apply
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default CancelBookingPopover;
