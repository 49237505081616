import {
  BsArchive,
  BsCheckCircle,
  BsCurrencyDollar,
  BsPlus,
  BsChevronLeft,
  BsChevronDown,
  BsChevronRight,
  BsFillCaretDownFill,
  BsChevronUp,
  BsFileText,
  BsGear,
  BsListUl,
  BsTag,
  BsTrash,
  BsArrowUpShort,
  BsArrowDownShort,
  BsCaretDown,
  BsCaretDownFill,
  BsCaretUp,
  BsCaretUpFill,
  BsGraphUp,
  BsGrid,
  BsCalendar4Week,
  BsCarFront,
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
  BsClock,
  BsFillSendFill,
  BsStar,
  BsStarFill,
  BsStarHalf,
} from "react-icons/bs";
import { HiOutlineBriefcase } from "react-icons/hi";
import {
  AiOutlineCalendar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineInfoCircle,
  AiOutlineMail,
  AiOutlineUser,
  AiOutlineMenuFold,
} from "react-icons/ai";
import {
  FiFilter,
  FiUserPlus,
  FiMapPin,
  FiMenu,
  FiUsers,
  FiMoon,
  FiSun,
  FiList
} from "react-icons/fi";
import { RiSteeringFill } from "react-icons/ri";
import { RxLinkBreak2 } from "react-icons/rx";
import {
  BiRadioCircleMarked,
  BiRefresh,
  BiBell,
  BiMoney,
} from "react-icons/bi";
import { FiLock, FiLogOut } from "react-icons/fi";
import {
  MdChecklist,
  MdContentPaste,
  MdOndemandVideo,
  MdPeopleAlt,
  MdOutlineAddLocationAlt,
  MdVerified,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdOutlineCancel,
  MdPayments,
} from "react-icons/md";
import {
  FaHandHoldingUsd,
  FaLock,
  FaRegHandshake,
  FaRegUserCircle,
} from "react-icons/fa";
import { CgSupport } from "react-icons/cg";
import { CloseIcon } from "@chakra-ui/icons"

const APP_ICONS = {
  SEND: BsFillSendFill,

  //NAVIGATION ICONS
  SUPPORT: CgSupport,
  LOGOUT: FiLogOut,
  STAR: BsStar,
  STAR_FILL: BsStarFill,
  STAR_HALF: BsStarHalf,
  EXPAND: BsArrowsAngleExpand,
  COLLAPSE: BsArrowsAngleContract,
  COSTBREAK: RxLinkBreak2,
  CANCEL: MdOutlineCancel,
  DASHBOARD: BsGrid,
  BOOKINGS: BsCalendar4Week,
  PROFILE: FaRegUserCircle,
  LOCK_OUTLINE: FiLock,
  ANALYTICS: BsGraphUp,
  UP: MdKeyboardArrowUp,
  DOWN: MdKeyboardArrowDown,
  MAP: FiMapPin,
  CUE: MdChecklist,
  DRIVERS: RiSteeringFill,
  CUSTOMERS: MdPeopleAlt,
  PARTNERS: FaRegHandshake,
  WITHDRAWAL_REQUESTS: FaHandHoldingUsd,
  REPORTS: MdContentPaste,
  SETTINGS: BsGear,
  MENU: FiMenu,
  EXAMS: BsFileText,
  EXAMS_LIST: BsListUl,
  COURSES: HiOutlineBriefcase,
  CATEGORIES_TAG: BsTag,
  ARCHIVE: BsArchive,
  LECTURES: MdOndemandVideo,
  USERS: FiUsers,
  EMAILS: AiOutlineMail,
  BIN: BsTrash,
  SINGLE_USER: AiOutlineUser,
  FILTER: FiFilter,
  CLOSE: CloseIcon,
  CLOCK: BsClock,
  WARNING: AiOutlineInfoCircle,
  PAYMENT: MdPayments,

  //actions
  EDIT: AiOutlineEdit,
  REFRESH: BiRefresh,
  ADD: BsPlus,

  BELL2: BiBell,

  //Navigation Icons
  LeftChevron: BsChevronLeft,
  RightChevron: BsChevronRight,
  UpChevron: BsChevronUp,
  DownChevron: BsChevronDown,
  UpArrow: BsArrowUpShort,
  DownArrow: BsArrowDownShort,
  CaretDown: BsCaretDown,
  CaretDownFill: BsCaretDownFill,
  CaretUp: BsCaretUp,
  CaretUpFill: BsCaretUpFill,

  DropDownIcon: BsFillCaretDownFill,

  //Color Modes
  LighMode: FiSun,
  DarkMode: FiMoon,

  Drawer: AiOutlineMenuFold,
  //Misc
  DOLLAR_SIGN: BsCurrencyDollar,
  SPENT_TIME: BiMoney,
  BOOKING_REQUEST: MdOutlineAddLocationAlt,
  CHECK_CIRCLED: BsCheckCircle,
  PERSON_PLUS: FiUserPlus,
  LOCK: FaLock,
  EYE_OFF: AiOutlineEyeInvisible,
  EYE: AiOutlineEye,
  CALENDAR: AiOutlineCalendar,
  PICKUP: BsCarFront,
  DROP_OFF: MdVerified,
  STOP: BiRadioCircleMarked,

  LOGS: FiList,

};

export default APP_ICONS;
