import React from "react";
import {
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { getBookingActions, isBookingActive } from "../../../../config/helpers/formUtil/bookingUtil";
import APP_ICONS from "../../../../config/constants/icons";
import CostBreakdown from "./CostBreakdown";
import CancelBooking from "./CancelBooking";
import { useBookingTrackLink } from "../../../../config/query/bookingQuery";

const BookingActions = ({ bookingQuery }) => {
  const costBreakdownDisclosure = useDisclosure();

  const { bookingId } = useParams();

  const trackBookingQuery = useBookingTrackLink(bookingId);

  const handleTrackBooking = () => {
    trackBookingQuery
      .mutateAsync()
      .then((res) => window.open(res?.linkUrl))
      .catch((err) => console.warn(err));
  };

  const bookingActions = getBookingActions({
    bookingStatus: bookingQuery?.data?.bookingStatus?.id,
    costBreakdownDisclosure,
    handleTrackBooking,
    trackBookingQuery
  });

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Heading fontSize="16px">Booking Details</Heading>

      <HStack spacing={1}>
        {bookingActions?.length > 0 && (
          <Menu size={"sm"}>
            <MenuButton as={Button} variant={"unstyled"}>
              <Button
                size="sm"
                rightIcon={<Icon as={APP_ICONS.DownChevron} boxSize="3" />}
              >
                Options
              </Button>
            </MenuButton>
            <MenuList>
              {bookingActions.map((item, index) => {
                if (!item.render) return null;
                return (
                  <MenuItem fontSize={"14px"} key={index} onClick={item.action}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>)}

        <Button
          isLoading={bookingQuery.isFetching}
          onClick={bookingQuery.refetch}
          size="sm"
        >
          Refresh
        </Button>

        {isBookingActive(bookingQuery?.data?.bookingStatus?.id) && (
          <CancelBooking bookingId={bookingId} refresh={bookingQuery.refetch} />
        )}
      </HStack>

      <CostBreakdown disclosure={costBreakdownDisclosure} />
    </Flex>
  );
};

export default BookingActions;
