import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  SimpleGrid,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { Marker } from "@react-google-maps/api";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import SimpleMap from "../../../../components/Maps/SimpleMap";
import { getDefaultCoordinates } from "../../../../config/helpers/locationHelper";
import FormButton from "../../../../components/Forms/FormButton";

const LocationInfo = ({ watch, onSubmit, isSubmitting, onClearForm }) => {
  const { colorMode } = useColorMode();
  const { pickup, destination, stops } = watch();

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md"> Location Info</Heading>
      </CardHeader>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <VStack spacing={5} align="stretch">
          <SimpleMap
            containerProps={{ h: "500px" }}
            center={
              pickup?.latLng ||
              destination?.latLng ||
              stops?.at(0)?.latLng ||
              getDefaultCoordinates()
            }
          >
            {pickup && (
              <Marker
                position={pickup?.latLng}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  strokeColor: getColor(colorKeys.pickup, colorMode),
                  scale: 7,
                }}
              />
            )}
            {destination && (
              <Marker
                position={destination?.latLng}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  strokeColor: getColor(colorKeys.dropoff, colorMode),
                  scale: 7,
                }}
              />
            )}
            {stops &&
              Array.isArray(stops) &&
              stops?.map((item) => (
                <Marker
                  key={item?.value}
                  position={item?.latLng}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    strokeColor: getColor(colorKeys.stop, colorMode),
                    scale: 10,
                  }}
                />
              ))}
          </SimpleMap>

          <SimpleGrid spacing={2} columns={2}>
            <FormButton
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
              onClick={onSubmit}
              w="full"
              containerStyles={{
                w: "full",
              }}
            >
              Create
            </FormButton>
          </SimpleGrid>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default LocationInfo;
