import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Delete, Post, Patch } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";

export const useUsers = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["users", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.USERS.list}?${appendQueryParams(params)}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
  });
};

export const useDeleteUser = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.USERS.list}/${id}`,
        token,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useUserDetail = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["userDetail", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.USERS.list}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useChangePassword = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.USERS.changePassword,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useActivateCustomer = () => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const data = await Patch({
        path: `${API_CONSTANTS.USERS.activate.replace(":id", id)}`,
        token,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(["users"])
  });
}

export const useBlockCustomer = () => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const data = await Patch({
        path: `${API_CONSTANTS.USERS.block.replace(":id", id)}`,
        token,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(["users"])
  });
}

export const useUpdateCorporatePay = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async ({ customerId, isCorporatePay }) => {
      const response = await Patch({
        path: API_CONSTANTS.USERS.updateCorporatePay.replace(":customerId", customerId).replace(":value", isCorporatePay),
        token,
        toastError: true,
        toastMessage: true,
      });
      return response;
    },
    onSuccess: () => queryClient.invalidateQueries(["users"])
  });
};