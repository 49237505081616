import React from 'react'
import { Checkbox, CheckboxGroup, Flex, FormControl, FormErrorMessage, FormLabel, Stack, Text, useColorMode, chakra } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const FormCheckbox = ({ options, defaultChecked, label, errors = {}, id, required = false, control, rules, stackDir = ['column', 'row'], labelProps, containerProps, hideLabel = false, stackProps, optionLabelProps }) => {
    const { colorMode } = useColorMode()
    if (required) {
        required = `${label} is required`
    }
    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                <FormControl
                    isInvalid={errors[id]}
                    {...containerProps}
                >
                    {!hideLabel &&
                        <FormLabel
                            htmlFor={id}
                            fontSize={"13px"}
                            color={getColor(colorKeys.primaryText, colorMode)}
                            {...labelProps}
                        >
                            {label} {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                        </FormLabel>
                    }
                    {options?.length === 1 ? (
                        <Checkbox
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            defaultValue={defaultChecked}
                            ref={ref}
                            {...rest}
                        >
                            <Text {...optionLabelProps}>{options[0].label || options[0]}</Text>
                        </Checkbox>
                    ) : (
                        <CheckboxGroup
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            defaultValue={defaultChecked}
                            ref={ref}
                            {...rest}
                        >
                            <Flex spacing={[1, 5]} direction={stackDir} as={Stack}   {...stackProps}>
                                {options?.map((item, index) =>
                                    <Checkbox key={index} value={String(item.value) || String(item)}>
                                        <Text>{item.label || item}</Text>
                                    </Checkbox>
                                )}
                            </Flex>
                        </CheckboxGroup>
                    )}
                    <FormErrorMessage>
                        {errors[id] && errors[id].message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}

export default FormCheckbox