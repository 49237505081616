import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error";
import AuthLayout from "../../components/BasicUI/AuthLayout";
import AdminLayout from "../../components/BasicUI/AdminLayout";
import Login from "../../views/auth/Login";
import ForgotPassword from "../../views/auth/ForgotPassword";
import Reset from "../../views/auth/Reset";
import Verify from "../../views/auth/Verify";
import Dashboard from "../../views/admin/Dashboard";
import Users from "../../views/admin/Users";
import Profile from "../../views/admin/Profile";
import ChangePassword from "../../views/admin/ChangePassword";
import UserDetails from "../../views/admin/Users/UserDetails";
import SetPassword from "../../views/auth/SetPassword";
import Bookings from "../../views/admin/Bookings";
import BookingForms from "../../views/admin/Bookings/BookingForms/index";
import BookingDetails from "../../views/admin/Bookings/BookingDetails";
import Billing from "../../views/admin/Billing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/auth/login"} replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/auth/login"} replace />,
      },
      {
        path: "login",
        element: <Login />,
      },

      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "reset-password",
        element: <Reset />,
      },
      {
        path: "verify",
        element: <Verify />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/admin/dashboard"} replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/:userId",
        element: <UserDetails />,
      },
      {
        path: "bookings",
        element: <Bookings />,
      },
      {
        path: "bookings/create",
        element: <BookingForms />,
      },
      {
        path: "bookings/:bookingId",
        element: <BookingDetails />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
    ],
  },
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
