
import axios from "axios";
import { BASE_URL } from "../constants/api";
import { CONTENT_TYPE } from "../constants";
import { isUnauthorized } from "../helpers/apiHelper";
import { TOAST_TYPES, makeToast } from "../helpers/toastHelper";

async function Delete({ path, token, toastError, toastMessage, contentType = CONTENT_TYPE.JSON }) {
  let url = BASE_URL + path;
  try {
    const tenant = localStorage.getItem("tenant")
    const headers = { "Content-Type": contentType, tenant }
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const response = await axios.delete(url, { headers })

    if (toastMessage) {
      let message = response.data.message?.message || "Success!"
      makeToast({ type: TOAST_TYPES.SUCCESS, message })
    }
    return response.data;
  } catch (error) {
    if (isUnauthorized(error)) {
      localStorage.clear();
      window.location.reload();
      return;
    }

    if (toastError) {
      let message = error?.response?.data?.message?.message || "Unknown error!"
      message = message.length > 130 ? "Unknown Error!" : message
      makeToast({ type: TOAST_TYPES.ERROR, message })
    }
    console.error("Error in Delete.js: ", error)
    throw new Error(error)
  }
}

export { Delete };