var Config = {
  ENVIRONMENT: "PRODUCTION", //current selected environment
  ENVIRONMENTS: {
    DEVELOPMENT: {
      BASE_URL: "https://dev-ydrive-as.azurewebsites.net/api",
      PERSIST_SECRET_KEY: "secret.dev",
      GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
      TRACK_LINK: "https://ydrivetrackingbeta.appnofy.com",
      STRIPE_PUBLISHABLE_KEY: 'pk_test_51N8NQjKe3xZKOjlDVHw3ddXdLEvROhpAehAlCtnVDk4YYLdQ32Z88W4nvsWHe0szDq6QxXkKZCaA3SXPq4UUl5Ii00fkyKBf1V',
    },
    STAGING: {
      BASE_URL: "https://stg-ydrive-as.azurewebsites.net/api",
      PERSIST_SECRET_KEY: "secret.staging",
      GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
      TRACK_LINK: "https://stg-ydrive-tracking.azurewebsites.net",
      STRIPE_PUBLISHABLE_KEY: 'pk_test_51NkqSwFaU5y50XiPIBCI748a2IHs5iUoGdTjna95drOpSRp1vHI7TigculEz7c03MwNtMsdNuWsAlWIeVUV3EiBh00oC3laHJO',
    },
    PRODUCTION: {
      BASE_URL: "https://api.ridemanagementsoftware.com/api",
      FILE_CONTAINER: "CONTAINER_ID",
      TENANT: "root",
      PERSIST_SECRET_KEY: "83jd9mjoaiskdp9kDASJDSAKDOFAKSdlaskf032kda",
      GOOGLE_MAP_API_KEY: "AIzaSyBZlU2NPuHvbtBARx1R4oQx46pMG417qZw",
      TRACK_LINK: "https://ydrivetrackingbeta.appnofy.com",
      STRIPE_PUBLISHABLE_KEY: 'pk_test_51JPOPOKgGItYXbfnXbexkCX4mTRXIa1UF2knFgzUrklPTwRNkpYEqnN6lgjTIEgoYskcIBmqI3mniftRjufzZ6Hc00zKzN5DVl',
    },
  },
};
Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;
