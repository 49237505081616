import React, { useState } from "react";
import { Box, Heading, Flex, Text } from "@chakra-ui/react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import useDebounce from "../../../config/hooks/useDebounce";
import sortOrders from "../../../config/constants/sortOrders";
import { useInvoices } from "../../../config/query/invoiceQuery";
import { formatAmount } from "../../../config/helpers/stringHelper";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import {
  formatDate,
  formatDateTimeFromNow,
} from "../../../config/helpers/dateHelper";

const Billing = () => {
  const [query, setQuery] = useState({
    PageNumber: 1,
    PageSize: 20,
    Sort: "lastModifiedOn",
    SortOrder: sortOrders.DESC,
  });
  const debouncedQuery = useDebounce(query);
  const invoiceQuery = useInvoices(debouncedQuery);

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">
          Billing
        </Heading>
      </Flex>
      <CustomTable
        searchPlaceholder="Search invoices"
        head={[
          {
            title: "Start Period",
            extractor: "startPeriod",
            isSortable: true,
            align: "left",
            component: (item) => (
              <Text fontSize="14px">{formatDate(item?.startPeriod)}</Text>
            ),
          },
          {
            title: "End Period",
            extractor: "endPeriod",
            isSortable: true,
            component: (item) => (
              <Text fontSize="14px">{formatDate(item?.endPeriod)}</Text>
            ),
          },
          {
            title: "Amount",
            extractor: "amount",
            isSortable: true,
            component: (item) => (
              <Text fontSize="14px">{formatAmount(item?.amount)}</Text>
            ),
          },
          {
            title: "Paid On",
            extractor: "paidOn",
            isSortable: true,
            component: (item) => (
              <Text fontSize="14px">{formatDate(item?.paidOn)}</Text>
            ),
          },
          {
            title: "Due Date",
            extractor: "dueDate",
            isSortable: true,
            component: (item) => (
              <Text fontSize="14px">{formatDate(item?.dueDate)}</Text>
            ),
          },
          {
            title: "Comments",
            extractor: "comments",
            align: "left",
            isSortable: true,
            component: (item) => <BreakText value={item?.comments} />,
          },
          {
            title: "Status",
            extractor: "status",
            component: (item) => (
              <StatusBadge activeStatus={1} value={item.paymentStatus} />
            ),
            isSortable: true,
            align: "center",
          },
          {
            title: "Last Modified",
            align: "center",
            extractor: "lastModifiedOn",
            isSortable: true,
            component: (item) => formatDateTimeFromNow(item?.lastModifiedOn),
          },
        ]}
        data={invoiceQuery?.data?.data || []}
        loading={invoiceQuery?.isLoading}
        totalResults={invoiceQuery?.data?.meta?.totalCount}
        totalPages={invoiceQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => invoiceQuery.refetch()}
        isRefreshing={invoiceQuery?.isFetching}
      />
    </Box>
  );
};

export default Billing;
