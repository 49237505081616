
import {
  chakra,
  Flex,
  Box,
  Text,
  useColorMode,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { getColor, colorKeys } from "../../../config/constants/appColors";

import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import FormInput from "../../../components/Forms/FormInput";
import { useForm } from "react-hook-form";
import { useNavigate, Navigate } from "react-router-dom";

import IMAGES from "../../../config/constants/images";

import { useSetPassword, useVerifySetPassword } from "../../../config/query/authQuery";
import { queryStringToObject } from "../../../config/helpers/queryHelper";
import { useSelector } from "react-redux";

const SetPassword = () => {
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const navigate = useNavigate();

  const { colorMode } = useColorMode();

  const setPasswordQuery = useSetPassword();
  const verifySetPasswordQuery = useVerifySetPassword()

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (values) => {
    setPasswordQuery
      .mutateAsync({
        userId: values?.userId,
        code: values?.code,
        password: values?.newPassword,
      })
      .then(() => navigate("/auth/login"))
      .catch((error) => console.error(error));
  }

  const queryString = queryStringToObject();

  useEffect(() => {
    verifySetPasswordQuery
      .mutateAsync({
        userId: queryString?.userId,
        code: queryString?.code
      })
      .then((res) => {
        setValue("userId", res.data?.userId)
        setValue("code", res.data?.code)
      })
      .catch(() => navigate("/auth/login"))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!queryString?.code || !queryString?.userId) {
    return <Navigate to="/auth/login" replace={true} />;
  }


  return (
    <Flex
      height={"100vh"}
      justify="center"
      align="center"
      bg={getColor(colorKeys.white, colorMode)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full" w="full">
        <Box
          h="full"
          background={`linear-gradient(90deg, rgba(43,61,79,0.61) 0%, rgba(43,61,79,0.61) 100%), url(${IMAGES.AUTH_HERO})`}
          display={{ base: "none", md: "block" }}
        />

        <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
          {verifySetPasswordQuery.isLoading
            ? <Box w="full" maxW="480px" p="10">
              <Text fontWeight={"bold"} textAlign={"center"} fontSize="20px" color={getColor(colorKeys.black, colorMode)}>
                Verifying Link...
              </Text>
            </Box>
            : <Box w="full" maxW="500px">
              <Box w="110px" m="auto">
                <Image w="full" h="full" src={tenant?.logoUrl} fallbackSrc={IMAGES.LOGO} alt="logo" />
              </Box>

              <Box>
                <Text
                  fontSize="30px"
                  textAlign="center"
                  color={getColor(colorKeys.primaryText, colorMode)}
                  mt={5}
                >
                  Set Password
                </Text>
                <Text
                  fontSize="18px"
                  textAlign="center"
                  color={getColor(colorKeys.primaryText, colorMode)}
                  mt={1}
                >
                  Set a password for your account
                </Text>
              </Box>
              <Flex
                mt={12}
                borderRadius={"5px"}
                justify={"center"}
                align={"center"}
                bg={getColor(colorKeys.purpleBlue, colorMode)}
                height={"55px"}
              >
                <Text
                  fontSize="13px"
                  textAlign="center"
                  lineHeight={"19px"}
                  fontWeight={"400"}
                  color={getColor(colorKeys.white, colorMode)}
                >
                  Set Your Password
                </Text>
              </Flex>
              <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">
                <FormInput
                  id={"newPassword"}
                  label={"New Password"}
                  type="password"
                  secure={true}
                  placeholder="Enter New Password"
                  required={true}
                  errors={errors}
                  control={control}
                  inputProps={{
                    size: "lg",
                    fontSize: "15px",
                    autoComplete: "new-password",
                  }}
                  containerStyles={{ mt: 5 }}
                  hideLabel={true}
                />

                <FormInput
                  id={"confirmPassword"}
                  label={"Confirm Password"}
                  type="password"
                  secure={true}
                  placeholder="Confirm Password"
                  required={true}
                  errors={errors}
                  control={control}
                  inputProps={{
                    size: "lg",
                    fontSize: "15px",
                    autoComplete: "new-password",
                  }}
                  containerStyles={{ mt: 5 }}
                  hideLabel={true}
                />

                <PrimaryButton
                  isLoading={setPasswordQuery.isLoading}
                  mt={5}
                  type="submit"
                  p="18px 33px"
                  bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                  w="full"
                  h="50px"
                  rounded="md"
                  fontWeight={"bold"}
                  color="white"
                >
                  Submit
                </PrimaryButton>
              </chakra.form>
            </Box>}
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default SetPassword;
