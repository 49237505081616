import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
  VStack,
  chakra,
  useColorMode,
  HStack,
  Image,
  Radio,
  IconButton,
  Icon,
  useDisclosure,
  Tooltip,
  createStandaloneToast,
  Wrap,
  WrapItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormRadio from "../../../components/Forms/FormRadio";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import DeletePopover from "../../../components/BasicUI/DeletePopover";
import APP_ICONS from "../../../config/constants/icons";
import CardForm from "./CardForm";
import {
  useCardList,
  useDeleteCardLoggedInCustomer,
  usePrimaryCard,
  useUpdatePaymentType,
  usePaymentType
} from "../../../config/query/profileQuery";
import { STRIPE_STATUS, STRIPE_STATUSES } from "../../../config/constants/enums";
import { patchUser } from "../../../config/redux/slices/userSlice";
import { formatPhoneNumberForDisplay } from "../../../config/helpers/stringHelper";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";

const Profile = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const formDisclosure = useDisclosure();
  const userData = useSelector((state) => state.user?.user);
  const useCardListQuery = useCardList();
  const deleteCardQuery = useDeleteCardLoggedInCustomer();
  const usePrimaryCardQuery = usePrimaryCard();
  const updatePaymentType = useUpdatePaymentType();
  const paymentTypeQuery = usePaymentType();

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    values: {
      stripeStatus: Number(userData?.corporatePaymentType?.id)
    }
  });

  const handleDelete = (id) => {
    deleteCardQuery
      .mutateAsync(id)
      .then(() => useCardListQuery.refetch())
      .catch((error) => console.error(error));
  };

  const setPrimaryCard = (id) => {
    usePrimaryCardQuery
      .mutateAsync(id)
      .then(() => useCardListQuery.refetch())
      .catch((error) => console.error(error));
  };

  const onCardFormSuccess = () => {
    const { toast } = createStandaloneToast()
    toast({
      title: 'Congrats!',
      description: "Card added successfully, it may show up after a few seconds",
      status: 'success',
      id: "Card added successfully",
      isClosable: true,
      position: "top",
      variant: "subtle"
    })
    setTimeout(() => {
      useCardListQuery.refetch();
    }, 2000);
  };

  useEffect(() => {
    if (!isNaN(watch("stripeStatus")) && watch("stripeStatus") !== userData?.corporatePaymentType?.id) {
      updatePaymentType
        .mutateAsync({
          corporatePaymentType: Number(watch("stripeStatus"))
        })
        .then(() => {
          paymentTypeQuery
            .mutateAsync()
            .then((res) => dispatch(patchUser({ corporatePaymentType: res?.data })))
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("stripeStatus")])

  return (
    <VStack w="full" spacing={5}>
      <Box w="full">
        <Heading fontSize={26}>Profile</Heading>
        <Card mt={3} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CardBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} w="full">
              <Flex align="center">
                <Avatar
                  size="xl"
                  bg={getColor(colorKeys.avatarBg, colorMode)}
                  name={userData?.name}
                  src={userData?.profilePictureUrl}
                />
                <VStack spacing={0} align={"stretch"} ml={3} maxW="450px">
                  <Text fontWeight={"bold"} fontSize="16px">
                    {userData?.name}
                  </Text>

                  {userData?.phone && (
                    <Text fontSize="14px">
                      {formatPhoneNumberForDisplay(userData?.phone)}
                    </Text>
                  )}

                  {userData?.email && (
                    <Text fontSize="14px"> {userData?.email}</Text>
                  )}
                </VStack>
              </Flex>
              <Flex flexDir={"column"} alignItems="self-end" alignSelf={"center"}>
                <LabelValuePair
                  label={"Corporate Code"}
                  value={userData?.corporateCode}
                  headingProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.secondaryText, colorMode),
                    fontWeight: "normal",
                  }}
                  valueProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.dimText, colorMode),
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
                <LabelValuePair
                  label={"Company Name"}
                  value={userData?.companyName}
                  headingProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.secondaryText, colorMode),
                    fontWeight: "normal",
                  }}
                  valueProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.dimText, colorMode),
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
                <LabelValuePair
                  label={"City"}
                  value={userData?.city.name}
                  headingProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.secondaryText, colorMode),
                    fontWeight: "normal",
                  }}
                  valueProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.dimText, colorMode),
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
              </Flex>
            </SimpleGrid>
          </CardBody>
        </Card>
      </Box>

      <Box w="full">
        <Heading fontSize={26}>Payment Settings</Heading>
        <Card mt={3} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CardBody p="25px">
            <chakra.form>
              <Flex justify={"space-between"}>
                {userData?.directInvoiceEnabled ? (
                  <FormRadio
                    id={"stripeStatus"}
                    label={"Stripe Status"}
                    hideLabel={true}
                    required={true}
                    errors={errors}
                    options={makeSelectList(STRIPE_STATUS)}
                    control={control}
                    stackProps={{
                      spacing: 100,
                      align: "stretch",
                      whiteSpace: "nowrap",
                      mb: "20px",
                    }}
                    defaultValue={Number(userData?.corporatePaymentType?.id)}
                  />
                ) : <Heading fontSize="20px">Cards</Heading>}

                {Number(watch("stripeStatus")) === STRIPE_STATUSES.Card && (
                  <PrimaryButton
                    bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                    onClick={() => formDisclosure.onOpen()}
                    rounded="md"
                    fontWeight={"bold"}
                    color="white"
                    icon={APP_ICONS.ADD}
                    p={"0px 40px"}
                    fontSize={"15px"}
                  >
                    Add
                  </PrimaryButton>
                )}
              </Flex>
              {Number(watch("stripeStatus")) === STRIPE_STATUSES.Card ? (
                <Wrap h="calc(100vh - 550px)">
                  {useCardListQuery?.data?.data?.map((item) => (
                    <WrapItem key={item.id}>
                      <Flex
                        w="full"
                        align="center"
                        justify={"space-between"}
                        cursor={"pointer"}
                        p="15px"
                        rounded={"5px"}
                        maxW={"350px"}
                        boxShadow={"0px 1px 3px 0px #00000040"}
                        mb={4}
                      >
                        <HStack>
                          <Radio
                            value={item.id}
                            onChange={() => setPrimaryCard(item.id)}
                            isChecked={item.isPrimary}
                          ></Radio>

                          <Image src={item?.logoUrl} h={"25px"} />
                          <Text pl={2}>**** {item?.lastFourDigits}</Text>
                        </HStack>
                        {item.isPrimary ? (
                          <Tooltip label="Primary card cannot be deleted">
                            <IconButton
                              isDisabled={true}
                              size="xs"
                              icon={<Icon as={APP_ICONS.BIN} />}
                            >
                              Delete
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <DeletePopover
                            type="card"
                            onConfirm={() => handleDelete(item.id)}
                          >
                            <IconButton
                              size="xs"
                              icon={<Icon as={APP_ICONS.BIN} />}
                            >
                              Delete
                            </IconButton>
                          </DeletePopover>
                        )}
                      </Flex>
                    </WrapItem>
                  ))}
                </Wrap>
              ) : (
                <Flex justify={"center"} align="center" w="full" h="calc(100vh - 550px)">
                  <Box m="auto">
                    <Heading textAlign={"center"} fontSize="24px">Direct Invoice Enabled</Heading>
                    <Text textAlign={"center"} color={getColor(colorKeys.gray, colorMode)}>
                      You can pay by card after disabling direct invoice
                    </Text>
                  </Box>
                </Flex>
              )}
            </chakra.form>
          </CardBody>
        </Card>
      </Box>
      <CardForm disclosure={formDisclosure} onSuccess={onCardFormSuccess} />
    </VStack>
  );
};

export default Profile;
