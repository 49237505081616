import { Box, Flex, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const LabelValueRow = ({ label, value, labelProps, valueProps }) => {
    const { colorMode } = useColorMode();
    if (!value || !label) return null
    return (
        <Flex py="2" px="2" _odd={{ backgroundColor: getColor(colorKeys.tableStripedRowBackground, colorMode) }}>
            <Box w="50%" fontSize="14px" fontWeight={"bold"} {...labelProps}>{label}</Box>
            <Box w="50%" fontSize="14px" {...valueProps}>{value}</Box>
        </Flex>
    )
}

export default LabelValueRow