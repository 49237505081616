import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Box, useColorMode } from '@chakra-ui/react';
import COLOR_MODES from '../../config/constants/colorModes';

const SimpleMap = React.forwardRef(({ containerProps, children, center }, ref) => {
    const { colorMode } = useColorMode();

    const mapStyles = {
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    };

    const getStylers = () => {
        if (colorMode === COLOR_MODES.DARK) {
            return [
                { saturation: -100 },
                { invert_lightness: true },
                { weight: 1.5 },
            ]
        } else {
            return [
                { invert_lightness: false },
                { weight: 1.5 },
            ]
        }
    }

    return (
        <Box w="full" h="calc(100vh - 250px)" ref={ref} {...containerProps}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={12}
                center={center ? center : null}
                options={{
                    draggableCursor: 'pointer',
                    draggingCursor: 'pointer',
                    controlSize: 24,
                    streetViewControl: false,
                    mapTypeControl: true,
                    fullscreenControl: true,
                    scrollwheel: true,
                    zoomControl: true,
                    scaleControl: true,
                    rotateControl: true,
                    disableDoubleClickZoom: true,
                    gestureHandling: 'greedy',
                    clickableIcons: false,
                    styles: [
                        {
                            featureType: 'all',
                            elementType: 'all',
                            stylers: getStylers(),
                        },
                    ],
                }}
            >
                {children}
            </GoogleMap>
        </Box>
    );
})

export default React.memo(SimpleMap);