import React from 'react'
import { Box, Card, CardBody, Heading, SimpleGrid, Text, VStack, useColorMode } from '@chakra-ui/react'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import { formatDateTime } from '../../../../config/helpers/dateHelper';
import { Navigate, useParams } from 'react-router';
import LabelValueRow from '../../../../components/BasicUI/DataBoxes/LabelValueRow';

const BookingData = ({ data }) => {
  const { colorMode } = useColorMode();
  const { bookingId } = useParams();

  if (!bookingId) return <Navigate to="/admin/bookings" />;
  return (
    <VStack align="stretch">
      <Card w="full" mt={5}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <SimpleGrid columns={{ base: 1, md: 4 }} w="full" mb={8}>
            <Box>
              <Heading mb={3} fontSize="16px">
                Passenger
              </Heading>
              <Text>{data?.passengerName}</Text>
              <Text>{data?.passengerPhoneNo} </Text>
            </Box>
            <Box>
              <Heading mb={3} fontSize="16px">
                Driver
              </Heading>
              <Text>{data?.driverName || "N/A"}</Text>
              <Text>{data?.driverPhoneNo || "N/A"} </Text>
            </Box>
            <Box>
              <Heading mb={3} fontSize="16px">
                Pickup/Dropff Time
              </Heading>
              <Text>{formatDateTime(data?.pickupTime) || "N/A"}</Text>
              <Text>{formatDateTime(data?.dropOffTime) || "N/A"}</Text>
            </Box>
            <Box>
              <Heading mb={3} fontSize="16px">
                Vehicles
              </Heading>
              <Text>{data?.licensePlate || "N/A"}</Text>
              <Text>{data?.model || "N/A"}</Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2 }} w="full" my={5}>
            <Box>
              <Heading mb={2} fontSize="16px">
                Cancellation Reason
              </Heading>
              <Text>{data?.cancellationType?.name || "N/A"}</Text>
              <Text>{data?.cancellationReason || "N/A"}</Text>
            </Box>
          </SimpleGrid>
        </CardBody>
      </Card>
      <Heading fontSize="16px">Notes</Heading>
      <Card w="full">
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <VStack align="stretch" w="full">
            {[
              {
                label: "Dispatcher Notes",
                value: data?.dispatcherNote || "N/A",
              },
              {
                label: "Customer Notes",
                value: data?.customerNote || "N/A",
              },
              {
                label: "Internal Notes" || "N/A",
                value: data?.internalNote,
              },
            ].map((item, index) => (
              <LabelValueRow
                labelProps={{ w: "250px" }}
                key={index}
                label={item.label}
                value={item.value || "-"}
              />
            ))}
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default BookingData;
