import React from "react";
import CancelBookingPopover from "../../../../components/BasicUI/Popovers/CancelBookingPopover";
import { useForm } from "react-hook-form";
import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import APP_ICONS from "../../../../config/constants/icons";
import FormTextarea from "../../../../components/Forms/FormTextarea";
import { useCancelBooking } from "../../../../config/query/bookingQuery";

const CancelBooking = ({ bookingId, refresh }) => {
  const disclosure = useDisclosure();
  const cancelBookingQuery = useCancelBooking();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onCancelBooking = (values) => {
    const otherReason = values.otherReason;
    cancelBookingQuery
      .mutateAsync({
        id: bookingId,
        body: { reason: 0, otherReason },
      })
      .then(() => refresh())
      .catch((err) => console.error(err));
  };

  return (
    <CancelBookingPopover
      onSubmit={handleSubmit(onCancelBooking)}
      disclosure={disclosure}
      triggerProps={{
        colorScheme: "red",
        size: "sm",
        fontSize: "14px",
        leftIcon: <Icon boxSize={5} as={APP_ICONS.CANCEL} />,
      }}
      heading="Cancel Booking"
      label="Cancel Booking"
      isSubmitting={cancelBookingQuery.isLoading}
    >
      <Box as="form">
        <FormTextarea
          id="otherReason"
          control={control}
          errors={errors}
          placeholder="Specify your reason"
          inputProps={{ size: "sm" }}
        />
      </Box>
    </CancelBookingPopover>
  );
};

export default CancelBooking;
