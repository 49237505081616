import React from "react"
import { chakra, useColorMode } from "@chakra-ui/react"
import { Select } from 'chakra-react-select'
import { colorKeys, getColor } from "../../../config/constants/appColors"
import APP_ICONS from "../../../config/constants/icons"

const CustomSelect = ({ value, onChange, options = [], multiple, placeholder, ...rest }) => {

    const { colorMode } = useColorMode()

    return (
        <Select
            allowClear={true}
            isClearable={true}
            isMulti={multiple}
            classNamePrefix={"custom-react-select"}
            onChange={(e) => {
                onChange(e?.value)
            }}
            value={options?.filter(function (option) {
                return option.value === value;
            })}
            placeholder={placeholder}
            options={options}
            {...rest}
            leftAddon={
                <chakra.span
                    fontSize="1.2em"
                    color={getColor(colorKeys.primary, colorMode)}
                    mr="0.5em"
                >
                    {APP_ICONS.SEARCH}
                </chakra.span>
            }
            chakraStyles={{
                container: (provided, state) => ({
                    ...provided,
                    width: "350px",
                    bg: getColor(colorKeys.tableBackground, colorMode),
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: "0.5em",
                }),
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                    minWidth: "200px",
                }),
            }}
            size={"sm"}
        >
        </Select>
    )
}

export default CustomSelect