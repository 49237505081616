import { Link, useRouteError } from "react-router-dom";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import IMAGES from "../../constants/images";

const ErrorPage = () => {
    const error = useRouteError();
    console.error("Unhandled Error Occurred! ", error);
    return (
        <Flex id="error-page" h="100vh" justify="center" align="center">
            <Box>
                <Image src={IMAGES.ERROR} />
            </Box>
            <Box>
                <Heading>Oops!</Heading>
                <Text>Sorry, an unexpected error has occurred.</Text>
                <Text>{error.statusText || error.message}</Text>
                <PrimaryButton mt={5}>
                    <Link to="/admin">
                        Back To Homepage
                    </Link>
                </PrimaryButton>
            </Box>
        </Flex>
    );
}

export default ErrorPage