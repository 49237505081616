import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";

export const useInvoices = (params) => {
    const token = useSelector((state) => state.user?.token);
    return useQuery({
        queryKey: ["direct-invoice", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.INVOICE.base}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false,
            });
            return data;
        },
    });
};
