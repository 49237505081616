import {
  Avatar,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  useColorMode,
  HStack,
  Box,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../../config/redux/slices/userSlice";
import NetworkBar from "../NetworkBar";
import {
  getPageTitle,
  singularize,
} from "../../../config/helpers/stringHelper";
import PathGuide from "./PathGuide";
import ColorModeSwitch from "../Buttons/ColorModeSwitch";
import CustomPopover from "../Popovers/CustomPopover";
import Notifications from "./Notifications";

const Header = ({ disclosure, desktopDisclosure }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const { pathname } = useLocation();

  const splitPath = pathname.split("/");

  const navigate = useNavigate();

  const { colorMode } = useColorMode();

  useEffect(() => {
    let title = splitPath[splitPath.length - 1];

    if (!isNaN(splitPath[splitPath.length - 1])) {
      title =
        singularize(splitPath[splitPath.length - 2].replace("-", " ")) +
        " " +
        "Details";
    }
    document.title = getPageTitle(title, tenant?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="full"
      px="4"
      minH="70px"
      bg={getColor(colorKeys.layoutHeaderBackground, colorMode)}
      h="14"
      pos="sticky"
      top="0"
      zIndex={99}
    >
      {/* mobile sider toggle button */}
      <IconButton
        aria-label="Menu"
        display={{
          base: "inline-flex",
          md: "none",
        }}
        onClick={disclosure.onOpen}
        icon={<Icon as={APP_ICONS.MENU} />}
        size="sm"
      />
      <Flex align="center">
        <IconButton
          aria-label="Menu"
          display={{
            base: "none",
            md: "inline-flex",
          }}
          onClick={() => desktopDisclosure.onToggle()}
          icon={<Icon as={APP_ICONS.Drawer} />}
          size="sm"
          mr={5}
        />
        <PathGuide />
      </Flex>
      <NetworkBar />
      <HStack spacing={4} align="center">
        <ColorModeSwitch />
        <Box>
          <CustomPopover
            heading="Notifications"
            label="Notifications"
            triggerProps={{
              as: IconButton,
              icon: <Icon boxSize="6" mt={2} as={APP_ICONS.BELL2} />,
              variant: "unstyled",
            }}
            placement="bottom-start"
            bodyProps={{ p: 0 }}
          >
            <Notifications />
          </CustomPopover>
        </Box>

        <Menu>
          <MenuButton as={Button} variant={"unstyled"}>
            <Flex align="center">
              <Avatar
                size="xs"
                name={user?.name}
                src={user?.profilePictureUrl}
                cursor="pointer"
                className="top-nav-avatar"
              />
              <Text ml={2} fontSize="sm" fontWeight="medium">
                {user?.name}
              </Text>
              <Icon mt={1} boxSize="2" as={APP_ICONS.DownChevron} ml={1} />
            </Flex>
          </MenuButton>
          <MenuList>
            {[
              {
                name: "Profile",
                icon: APP_ICONS.PROFILE,
                action: () => navigate("/admin/profile"),
              },
              {
                name: "Change Password",
                icon: APP_ICONS.LOCK_OUTLINE,
                action: () => navigate("/admin/change-password"),
              },
              {
                name: "Log Out",
                icon: APP_ICONS.LOGOUT,
                action: () => dispatch(resetUser()),
              },
            ].map((item, index) => (
              <MenuItem
                icon={<Icon mt="3px" as={item.icon} boxSize="4" />}
                key={index}
                onClick={item.action}
                fontSize="14px"
              >
                {item.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

export default Header;
