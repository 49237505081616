import React from "react";
import {
  chakra,
  Flex,
  Button,
  Box,
  Text,
  useColorMode,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import FormInput from "../../../components/Forms/FormInput";
import { useForgotPassword } from "../../../config/query/authQuery";
import IMAGES from "../../../config/constants/images";
import { addTempEmail } from "../../../config/redux/slices/userSlice";

const ForgotPassword = () => {
  const tenant = useSelector((state) => state?.tenant?.tenant)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  const forgotPasswordQuery = useForgotPassword();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    forgotPasswordQuery
      .mutateAsync(values)
      .then((response) => {
        resetForm();
        dispatch(addTempEmail(response.data));
        navigate(`/auth/verify`);
      })
      .catch((error) => console.error(error));
  }

  return (
    <Flex
      height={"100vh"}
      justify="center"
      align="center"
      bg={getColor(colorKeys.white, colorMode)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} h="full" w="full">
        <Box
          h="full"
          background={`linear-gradient(90deg, rgba(43,61,79,0.61) 0%, rgba(43,61,79,0.61) 100%), url(${IMAGES.AUTH_HERO})`}
          display={{ base: "none", md: "block" }}
        />

        <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
          <Box w="full" maxW="500px">
            <Box w="110px" m="auto">
              <Image w="full" h="full" src={tenant?.logoUrl} fallbackSrc={IMAGES.LOGO} alt="logo" />
            </Box>

            <Box>
              <Text
                fontSize="30px"
                textAlign="center"
                fontWeight="500"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={5}
              >
                Forgot Password?
              </Text>
              <Text
                fontSize="18px"
                textAlign="center"
                color={getColor(colorKeys.primaryText, colorMode)}
                mt={1}
              >
                Reset Your Password via Email
              </Text>
            </Box>
            <Flex
              mt={12}
              borderRadius={"5px"}
              justify={"center"}
              align={"center"}
              bg={getColor(colorKeys.purpleBlue, colorMode)}
              height={"55px"}
            >
              <Text
                fontSize="13px"
                textAlign="center"
                lineHeight={"19px"}
                fontWeight={"400"}
                color={getColor(colorKeys.white, colorMode)}
              >
                Enter your Email and instructions will be sent to Reset your
                Password
              </Text>
            </Flex>

            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">
              <FormInput
                id={"email"}
                label={"Email"}

                placeholder="Enter Email Address"
                required={true}
                errors={errors}
                control={control}
                inputProps={{ size: "lg", fontSize: "15px" }}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}

              />

              <PrimaryButton
                isLoading={forgotPasswordQuery.isLoading}
                mt={10}
                type="submit"
                p="18px 33px"
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                w="full"
                h="50px"
                rounded="md"
                fontWeight={"light"}
                color="white"
              >
                Reset
              </PrimaryButton>
            </chakra.form>
          </Box>

          <Box
            fontSize="15px"
            color={getColor(colorKeys.secondaryText, colorMode)}
            textAlign="center"
          >
            <Flex textAlign={"center"} justify="center">
              <Text mr={1}>Remember? </Text>
              <Link to="/auth/login">
                <Button
                  variant="link"
                  type="button"
                  color={getColor(colorKeys.secondaryText, colorMode)}
                >
                  Sign in Now
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default ForgotPassword;
