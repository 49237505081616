import { BOOKINGS_STATUSES } from "../../constants/enums"

export const renderTrackBooking = (bookingStatus) => {
  return bookingStatus === BOOKINGS_STATUSES.Active;
};

export const getBookingActions = ({ trackBookingQuery, handleTrackBooking, bookingStatus, costBreakdownDisclosure }) => {
  return [
    {
      name: "Cost Breakdown",
      action: costBreakdownDisclosure?.onOpen,
      render: bookingStatus === BOOKINGS_STATUSES.Finished,
    },

    {
      name: "Track Booking",
      isLoading: trackBookingQuery?.isLoading,
      action: handleTrackBooking,
      render: renderTrackBooking(bookingStatus),
    },
  ]
}

export const isBookingActive = (bookingStatus) => {
  return Boolean(
    bookingStatus === BOOKINGS_STATUSES.Active ||
    bookingStatus === BOOKINGS_STATUSES.Scheduled
  )
}

//if this function returns true show apply button or else show applied
export const getPromoCodeStatus = ({ promoCode, promoCodeData }) => {
  if (!promoCode) {
    return false
  }
  if (promoCode === promoCodeData?.name) {
    return false //means applied
  }
  else if (!promoCodeData) {
    return true //means apply
  }
  else {
    return true
  }
}