import Config from "../../config";

export const BASE_URL = localStorage.getItem("temp-base-url") || Config.env().BASE_URL;

const API_CONSTANTS = {
  AUTH: {
    login: "/corporate/tokens",
    forgetPassword: "/corporate/users/forgot-password",
    verifyOtp: "/corporate/users/verify-token",
    resetPassword: "/corporate/users/reset-password",
    setPassword: "/corporate/users/set-password",
    verifySetPassword: "/corporate/users/verify-set-password",
    getTenantDetails: "/corporate/personal/tenant-by-url"
  },

  BOOKINGS: {
    list: "/corporate/booking/list",
    bookingBase: "/corporate/booking",
    lookup: "/corporate/customer/lookup",
    viewCorporateCustomer: "/corporate/customer",
    bookingTrackingLink: "/corporate/booking/tracking-link",
    typesLookup: "/corporate/transport/vehicle-types/lookup",
    searchVehicleType: "/corporate/booking/search",
    searchUserByPhone: "/corporate/customer/lookup/:phoneNumber",
    getCustomerById: "/corporate/customer/:id",
    createBookingCustomer: "/corporate/customer/booking-customer",
    cancelBooking: "/corporate/booking/:id/cancel",
    applyPromoCode: "/corporate/booking/apply-promocode",
    costBreakdown: "/corporate/booking/:id/cost-breakdown",
    corporateBookings: "/corporate/booking/customer-booking-list",
    // trackBooking: "/corporate/booking/:id/tracking-link",
    dataKeys: [
      "flightNumber",
      "gateNumber",
      "rideType",
      "rideTime",
      "customerId",
      "paymentMethod",
      "creditCardId",
      "vehicleTypeId",
      "dispatcherNote",
      "internalNote",
      "promoCodeId",
      "quotedFare",
      // "tip",
      "bookingStops",
    ],
  },
  USERS: {
    list: "/corporate/customer",
    changePassword: "/corporate/users/change-password",
    activate: "/corporate/customer/:id/activate",
    block: "/corporate/customer/:id/block",
    updateCorporatePay: "/corporate/customer/:customerId/update-corporate-pay?isCorporatePay=:value",
  },
  CORPORATE: {
    base: "/corporate/personal/profile",
    create: "/corporate/booking/create-customer",
    dataKeys: ["firstName", "lastName", "phone", "email"],
  },
  DASHBOARD: {
    spendingGraph: "/corporate/dashboard/corporate-spending-graph",
    stats: "/corporate/dashboard/corporate-dashboard-stats",
  },
  PROFILE: {
    updatePaymentType: "/corporate/personal/payment-method",
    setupIntent: "/corporate/card/setup-intent",
    cardList: "/corporate/card/list",
    deleteList: "/corporate/card",
    setPrimaryCard: "/corporate/card/set-primary",
  },
  NOTIFICATIONS: {
    list: "/common/notification"
  },
  INVOICE: {
    base: "/corporate/directinvoice"
  }
};

export default API_CONSTANTS;
