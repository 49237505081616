import { parsePhoneNumber } from "libphonenumber-js";

export const getPageTitle = (title = "Home", name) => {
  const appName = name ? `${name} Corporate Dashboard` : "Corporate Dashboard"
  return `${title?.charAt(0).toUpperCase() + title?.slice(1)} | ${appName}`;
};

export const pluralize = (noun, suffix = "s") => `${noun}${suffix}`;

export const singularize = (noun) => {
  if (noun.endsWith("ies")) {
    return noun.replace("ies", "y");
  }
  if (noun.endsWith("s")) {
    return noun.slice(0, -1);
  }
  return noun;
};

export const accessValue = (object, path) => {
  if (typeof object !== 'object' || typeof path !== 'string') {
    throw new Error('Invalid input');
  }
  const properties = path.split('.');
  let value = object;
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    value = value?.[property];

    if (value === undefined) {
      return undefined;
    }
  }
  return value;
}

export const formatAmount = (amount, fallBackText = "$0.00") => {
  if (!amount) return fallBackText
  return amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const generateString = (length, onlyCaps = false, onlyNumbers = false) => {
  length = length ? length : 8;
  let charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  if (onlyCaps) {
    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }
  if (onlyNumbers) {
    charset = "1234567890";
  }
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const reshapePhoneNumber = (phoneNumber) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  return numericPhoneNumber;
}

export const formatPhoneNumberForDisplay = (phoneNumber) => {
  try {
    let parsedNumber = parsePhoneNumber(phoneNumber)
    return parsedNumber.formatInternational()
  } catch (error) {
    return phoneNumber
  }
}

export const formatPhoneNumber = (countryCode, phoneNumber) => {
  try {
    let parsedNumber = parsePhoneNumber(`${countryCode}${phoneNumber}`)
    return parsedNumber.nationalNumber
  } catch (error) {
    return phoneNumber
  }
}

// export const formatPhoneNumberForDisplay = (phoneNumber) => {
//   try {
//     let parsedNumber = parsePhoneNumber(phoneNumber)
//     return parsedNumber.formatInternational()
//   } catch (error) {
//     return phoneNumber
//   }
// }

export const removePlusSign = (value) => {
  return value?.replace("+", "")
}

export const formatPercentage = (value) => {
  if (!value) return "0%"
  return `${value}%`
}

export const breakWithCaps = (str) => {
  if (!str) return ""
  return String(str).replace(/([a-z])([A-Z])/g, '$1 $2');
}