export const CONTENT_TYPE = {
    JSON: "application/json",
    FORM_DATA: "multipart/form-data",
}

export const DEFAULT_DEBOUNCE_TIME = 250;
export const DEFAULT_DATETIME_FORMAT = "DD-MMM-YYYY hh:mm A"
export const DEFAULT_DATE_FORMAT = "DD-MMM-YYYY"
export const DEFAULT_DATE_TIME_WITH_ZONE = "YYYY-MM-DD hh:mm:ss A (UTCZ"
export const DEFAULT_DATETIME_FORMAT_WITH_SECONDS = "DD-MMM-YYYY hh:mm:ss A";
export const DEFAULT_HIDDEN_SIDEBAR_PAGES = [
    "/admin/dashboard",
    "/admin/new-booking",
    "/admin/analytics",
    "/admin/map",
    "/admin/cue",
    "/admin/drivers",
    "/admin/customers",
    "/admin/corporate",
    "/admin/partners",
    "/admin/queuing-area",
    "/admin/withdrawal-requests",
]

export const DEFAULT_COUNTRY_CODE = "+1"
