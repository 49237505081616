import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";

export const useDashboard = () => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["dashboard"],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CORPORATE.base}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
};

export const useSpendingGraph = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["dashboard-graph", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.DASHBOARD.spendingGraph}?${appendQueryParams(params)}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
}

export const useDashboardStats = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["dashboard-stats", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.DASHBOARD.stats}?${appendQueryParams(params)}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
}