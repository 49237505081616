import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: "",
  reset: {
    tempEmail: "",
    tempToken: "",
  },
  chatId: "",
  pushToken: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    updateUser: (state, { payload }) => {
      state.user = payload;
    },
    updateToken: (state, { payload }) => {
      state.token = payload;
    },
    resetUser: (state) => {
      state.user = {};
      state.token = "";
      state.reset = {
        tempEmail: "",
        tempToken: "",
      };
    },
    addTempEmail: (state, { payload }) => {
      state.reset.tempEmail = payload;
    },
    addTempToken: (state, { payload }) => {
      state.reset.tempToken = payload;
    },
    addChatId: (state, { payload }) => {
      state.chatId = payload;
    },
    resetTemp: (state) => {
      state.reset = {
        tempEmail: "",
        tempToken: "",
      };
    },
    patchUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    updatePushToken: (state, { payload }) => {
      state.pushToken = payload;
    },
  },
  extraReducers: {},
});

export const {
  addUser,
  resetUser,
  updateUser,
  addTempEmail,
  addTempToken,
  resetTemp,
  addChatId,
  patchUser,
  updatePushToken
} = userSlice.actions;

export default userSlice.reducer;
