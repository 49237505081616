import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import BookingData from "./BookingData";
import { useParams } from "react-router";
import { useBooking } from "../../../../config/query/bookingQuery";
import TripData from "./TripData";

import BookingActions from "./BookingActions";

const BookingDetails = () => {
  const { bookingId } = useParams();

  const bookingQuery = useBooking(bookingId);

  return (
    <Box>
      <VStack spacing={5}>
        <Box width={"full"}>
          <BookingActions bookingQuery={bookingQuery} />
          <BookingData
            data={bookingQuery.data}
            isLoading={bookingQuery.isLoading}
            bookingId={bookingId}
          />
        </Box>
        <TripData data={bookingQuery.data} />
      </VStack>
    </Box>
  );
};

export default BookingDetails;
