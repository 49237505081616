import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  VStack,
  useColorMode,
  SimpleGrid,
  Divider,
  chakra,
  HStack,
  IconButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormInput from "../../../../components/Forms/FormInput";
import AddNewUser from "./AddNewUser";
import {
  useSearchUserByPhone,
  useGetCustomerById,
} from "../../../../config/query/bookingQuery";
import APP_ICONS from "../../../../config/constants/icons";
import { removePlusSign } from "../../../../config/helpers/stringHelper";

const PassengerInfo = ({ control, errors, watch, setValue }) => {
  const formDisclosure = useDisclosure();
  const { colorMode } = useColorMode();

  const [addNewUsers, setAddNewUsers] = useState(false);

  const { localNumber } = watch();

  const searchUserByPhoneQuery = useSearchUserByPhone(localNumber);
  const getCustomerById = useGetCustomerById();

  const handleAddUser = (data) => {
    setValue("localNumber", removePlusSign(data.localNumber));
    getUserByPhone(data.localNumber);
  };

  const getUserByPhone = (value) => {
    const phoneValue = value ? removePlusSign(value) : removePlusSign(localNumber)
    searchUserByPhoneQuery
      .mutateAsync(phoneValue)
      .then((res) => {
        if (res.data?.length > 0) {
          getCustomerById
            .mutateAsync(res.data[0].id)
            .then((res) => {
              setValue("userId", res.id);
              setValue("email", res.email);
              setValue("firstName", res.firstName);
              setValue("lastName", res.lastName);
              setValue("customerId", res.id);
              setValue("isCorporatePay", res.isCorporatePay);
              setAddNewUsers(false);
            })
            .catch((err) => console.error(err));
        } else {
          setValue("userId", null);
          setValue("email", "");
          setValue("firstName", "");
          setValue("lastName", "");
          setValue("customerId", null);
          setAddNewUsers(true);
        }
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (typeof localNumber !== "undefined" && localNumber.length > 9) {
      getUserByPhone();
    }
  }, [localNumber]);

  const renderAddNewUser = (addNewUsers) => {
    if (addNewUsers === true) {
      return (
        <HStack justify="space-between">
          <HStack spacing={1}>
            <chakra.p
              color={getColor(colorKeys.dimBlue, colorMode)}
              fontSize="13px"
            >
              In this phone number User is not found. Please add User.
            </chakra.p>
            <IconButton
              size={"sm"}
              bg={getColor(colorKeys.primaryButtonFill, colorMode)}
              onClick={() => formDisclosure.onOpen()}
              color={getColor(colorKeys.white, colorMode)}
              aria-label="Search database"
              icon={<Icon boxSize={7} as={APP_ICONS.ADD} />}
            />
          </HStack>
        </HStack>
      );
    }
  };

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Passenger Info</Heading>
      </CardHeader>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <VStack spacing={5} align="stretch" divider={<Divider />}>
          <VStack spacing={5} align="stretch">
            <FormInput
              id="localNumber"
              label={"Phone Number"}
              control={control}
              errors={errors}
              required={true}
              placeholder="Enter phone number"
              inputProps={{ size: "sm" }}
            />

            {renderAddNewUser(addNewUsers)}

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>

              <FormInput
                id="firstName"
                label={"First Name"}
                control={control}
                errors={errors}
                placeholder="Enter first name"
                inputProps={{ size: "sm", isReadOnly: true }}
              />

              <FormInput
                id="lastName"
                label={"Last Name"}
                control={control}
                errors={errors}
                placeholder="Enter last name"
                inputProps={{ size: "sm", isReadOnly: true }}
              />

            </SimpleGrid>

            <FormInput
              id="email"
              label={"Email"}
              control={control}
              errors={errors}
              placeholder="Enter email"
              inputProps={{ size: "sm", isReadOnly: true }}
              type={"email"}
            />

            <FormInput
              label={"Flight Number"}
              control={control}
              errors={errors}
              id="flightNumber"
              placeholder="Enter number"
              inputProps={{ size: "sm" }}
            />
            <FormInput
              label={"Gate Number"}
              control={control}
              errors={errors}
              id="gateNumber"
              placeholder="Enter number"
              inputProps={{ size: "sm" }}
            />
          </VStack>
        </VStack>

        <AddNewUser
          disclosure={formDisclosure}
          localNumber={localNumber}
          onUserData={handleAddUser}
        />

      </CardBody>
    </Card>
  );
};

export default PassengerInfo;
